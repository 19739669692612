import React from 'react';
import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import LeaderboardCard from '../components/LeaderboardCard';
import { useNavigate } from "react-router-dom"
import MoonLoader from "react-spinners/MoonLoader";
import { GAMEMODE } from '../misc/enums'
import {isMatchPlay, isBestBall, isStandardLeaderboard, isBestBallMatch, isSkins} from '../misc/checks'
import { LeaderboardPageMatch } from './LeaderboardPageMatch';
import { LeaderboardTeamsPage } from './LeaderboardTeamsPage';
import { LeaderboardPageStandard } from './LeaderboardPageStandard';

export const LeaderboardPage2nd = (props) => {
    // const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState();

    //Firest check if we've got en external request.
    const [searchParams, setSearchParams] = useSearchParams();
    var round_uuid = searchParams.get("round_uuid")

    if (round_uuid === null) {
        round_uuid = props.round_uuid
    }

    useEffect(() => {

        const get_data = async () => {

            const data = await (
                await fetch('/api/leaderboard?' + new URLSearchParams({
                    round_uuid: round_uuid,
                    secondary: props.secondary
                }), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.token
                    }
                })
            ).json();
            
            setData(data)
            setLoading(false);

        };

        get_data();

    }, []);

    if (isLoading) {
        return <>
            <div className='centering-loading'>
                < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
            </div>
        </>;
    }

    return (
        <>
            {/* Display your secondary scorecard if*/}
            <LeaderboardPageStandard
                token={props.token}
                leaderboard={data.leaderboard}
                gameFormat={data.game_format}
                round_uuid={round_uuid}
                data={data}
                secondary={props.secondary}
            />
        </>
    )
}

