import React from 'react'
import { useEffect, useState } from "react";
import Friendslist from '../components/Friendslist'
import { useNavigate } from "react-router-dom"
import './SettingsPage.css'
import regWorker from '../reg_sw';
import MoonLoader from "react-spinners/MoonLoader";
import { SelectStyle } from "../misc/styles";
import Select from 'react-select';
import { delay } from '../misc/utils';

export const SettingsPage = (props) => {

  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [profileData, setProfileData] = useState('')

  const [profileActive, setProfileActive] = useState(true)
  const [friendsActive, setFriendsActive] = useState(false)
  const [roundsActive, setRoundsActive] = useState(false)

  const [email, setEmail] = useState(false)
  const [defaultGender, setDefaultGender] = useState()

  const { clickButton, isSub } = regWorker(props.token);
  const [bthLoad, setBtnLoad] = useState(false)

  useEffect(() => {

    const dataFetch = async () => {
      const data = await (
        await fetch('/api/settings', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          }
        })
      ).json();

      var hcp = 0

      if (data.hcp < 0) {
        hcp = '+' + -1 * data.hcp
      } else {
        hcp = data.hcp.toString();
      }

      setEmail(data.email)

      if (data.gender === true) {
        setDefaultGender({ value: 1, label: "Male" })
      } else if (data.gender === false) {
        setDefaultGender({ value: 0, label: "Female" })
      } else {
        setDefaultGender({ value: 3, label: "Unknown" })
      }

      setProfileData(({
        nickname: data.nickname,
        hcp: hcp,
        gender: data.gender
      }))

      setLoading(false);

    };

    dataFetch();

  }, [])


  const handleUserSubmit = (event) => {
    event.preventDefault();
    setBtnLoad(true);

    var body = {
      "nickname": profileData.nickname,
      "hcp": profileData.hcp,
      "gender": profileData.gender
    }

    const set_data = async () => {
      const data = await (
        await fetch('/api/settings', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          },
          body: JSON.stringify(body)
        })
      ).json()

      await delay(200)

      if (data.msg === 'Success') {
        setBtnLoad(false)
      }

    };

    set_data();

  }

  if (isLoading) {
    return <>
      <div className='centering-loading'>
        < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
      </div>
    </>;
  }


  return (
    <>
      <div className='centering-container'>
        <div className='gb-background' style={{ maxWidth: '300px' }}>
          <div className='settings-header-container'>
            <div className='profile-c' onClick={() => { setProfileActive(true); setFriendsActive(false); setRoundsActive(false) }} style={{ fontSize: profileActive ? '16pt' : '' }}> Profile </div>
            <div className='friends-c' onClick={() => { setProfileActive(false); setFriendsActive(true); setRoundsActive(false) }} style={{ fontSize: friendsActive ? '16pt' : '' }}> Friends </div>
          </div>

          <div style={{ display: profileActive ? '' : 'none' }}>
            <form onSubmit={handleUserSubmit}>
              <div className="form-ctrl">
                <label>Name</label>
                <input type='text'
                  placeholder=""
                  value={profileData.nickname || ''}
                  onChange={(e) =>
                    setProfileData(({
                      nickname: e.target.value,
                      hcp: profileData.hcp,
                      gender: profileData.gender
                    }))}
                ></input>
              </div>

              <div className="form-ctrl">
                <label>HCP</label>
                <input type='text'
                  placeholder=""
                  value={profileData.hcp || ''}
                  onChange={(e) => setProfileData(({
                    nickname: profileData.nickname,
                    hcp: e.target.value,
                    gender: profileData.gender
                  }))}
                ></input>
              </div>

              <div className="form-ctrl">
                <label>Gender</label>
                <Select
                  className="input-setup"
                  options={[{ value: 1, label: "Male" }, { value: 0, label: "Female" }]}
                  defaultValue={defaultGender || 'Select'}

                  onChange={(e) =>
                    setProfileData(({
                      nickname: profileData.nickname,
                      hcp: profileData.hcp,
                      gender: e.value
                    }))}

                  styles={SelectStyle}
                  isSearchable={false}
                />
              </div>

              <button style={{ marginTop: '5px', width: '220px' }}>
                {bthLoad ?
                  <div className='centering-loading'>
                    < MoonLoader color="rgba(0, 0, 0, 1)" size={20} />
                  </div>
                  :
                  'Save'
                }
              </button>

            </form>

            <div style={{ display: profileActive ? '' : 'none' }}>

              <div className="form-ctrl" >
                <label>Notifications</label>
                <button onClick={() => clickButton()} style={{ backgroundColor: isSub() ? '#2FE33E' : '#FF8282', marginTop: '3px' }}>
                  Enable
                </button>
              </div>

              <div className="form-ctrl" >
                <label>Password</label>
                <button onClick={() => navigate("/resetpassword")} style={{ marginTop: '3px' }}>
                  Reset password
                </button>
              </div>

              <button className='settings-button-logout' style={{ width: '150px', marginTop: '50px', float: 'right' }} onClick={() => navigate("/logout")}> Logout </button>
            
            </div>
          </div>

          <div style={{ display: friendsActive ? '' : 'none' }}>
            <Friendslist addX={true} addInput={true} />
          </div>

        </div>
      </div>
    </>
  )
}