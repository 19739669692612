
export const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  export const TEXTS = {
    STABLEFORD : "The player with the most points wins! Players earn points based on their performance relative to a target score - usually par. Scoring below par earns points. Handicaps can be factored in to allow players of varying skill levels to compete more closely.",
    STROKEPLAY : "Every shot counts - the player with the fewest total strokes wins!",
    MATCHPLAY : "In golf match play, two golfers or teams compete on each hole, aiming to win that specific hole by completing it with fewer strokes than their opponent.",
    MATCHPLAYTEAMS : "In golf match play, two golfers or teams compete on each hole, aiming to win that specific hole by completing it with fewer strokes than their opponent.",
    BESTBALL: "In golf best ball, teams of two or more golfers play their own balls throughout the round. After each hole, the lowest score among team members is used as the team's score for that hole. The team with the lowest cumulative score at the end of the round wins, fostering teamwork and strategy.",
    SKINS: "In golf skins, each hole has a designated monetary value (skin). Golfers compete to win the skin by having the lowest score on that hole. If there's a tie, the skin carries over to the next hole. The golfer with the most skins at the end of the round wins the pot, often making for an exciting and competitive game.",
    BESTBALLTALIBAN: "In golf best ball with worst ball as a decider, teams of two compete. Each player hits their own ball, and the best score on each hole is used for the team's score. However, if both players score the same, the team's score for that hole reverts to the worst ball score as a tiebreaker. It adds an element of strategy and pressure to the game."
}


export const TOOLTIP = {
  SHOW_WINNER : "The score for the last three holes won't be reported onto the scorecard or leaderboard.",
  TOURNAMENT: "A golf tournament is a competitive event where you compete over several rounds. Enabling this feature will disable the players from modifying the round settings.",
  ADD_TOURNAMENT: "To add another round, first finish a round and you'll be able to add another one. You don't need to play a tournament to add rounds, but it will be easier to distinguish",
  HANDICAP: "Enable or disable handicap.",
  RESETPASSWORD: "input your email and press reset password, a confirmation email will be sent to your email.",
  QR_CODE: "Advanced...",
  FROM_FILE: "Advanced...",
  SKINS_CARRY:"If a skin is not won outright (due to a tie on the lowest score), the value of that skin is added to the next hole's skin, making it even more valuable. This continues until someone wins a hole outright.",
  ROUNDEVENT:"Standard: You setup your round and configure everything and then starts. Open Play: Setup a round, and players can join freely and play when they want."
}