import React from "react"
import { useSearchParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom"
import MoonLoader from "react-spinners/MoonLoader";
import { SelectStyle } from "../misc/styles";
import Select from 'react-select';

export const OpenplayPage = (props) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setLoading] = useState(true);

    const [defaultGroup, setDefaultGroup] = useState()
    const [defaultTee, defaultTees] = useState()

    var round_uuid = searchParams.get("round_uuid")
    var round_pw = searchParams.get("round_pw")

    const teeRef = useRef()
    const groupRef = useRef()

    useEffect(() => {
        const dataFetch = async () => {

            const data = await (
                await fetch('/api/lobby/openplay?' + new URLSearchParams({
                    round_uuid: round_uuid,
                    round_pw: round_pw
                }), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.token
                    }
                })

            ).json();

            setDefaultGroup(data.groups)
            defaultTees(data.tee)

            setLoading(false);

        };

        dataFetch();

    }, []);


    if (isLoading) {
        return <>
            <div className='centering-loading'>
                < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
            </div>
        </>;
    }

    return (
        <>
            <div className="centering-container">
                <div className="gb-background"  >
                    <Select
                        className="input-setup"
                        options={defaultGroup}
                        defaultValue={{ value: "", label: "Select Group" }}
                        ref={groupRef}
                        styles={SelectStyle}
                        isSearchable={false}
                    />

                    <Select
                        className="input-setup"
                        options={defaultTee}
                        defaultValue={{ value: "", label: "Select Tee" }}
                        styles={SelectStyle}
                        isSearchable={false}
                        ref={teeRef}
                    />

                    <button style={{ width: '200px', marginTop: '20px' }} onClick={handleClick}> Join </button>

                </div>

            </div>

        </>
    )

    function handleClick() {

        var body = {
            "tee": teeRef.current.getValue()[0],
            "group": groupRef.current.getValue()[0],
            "round_uuid": round_uuid
        };

        const set_data = async () => {

            const data = await (
                await fetch('/api/lobby/join_openplay', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.token
                    },
                    body: JSON.stringify(body)
                })
            ).json()


            if(data.msg=="Success"){
                props.setUpdate(true)
                navigate("/editround");
            }else if(data.msg=="Group is full"){
                alert(data.msg)
            }else if(data.msg=="Make sure you selected both tee and group"){
                alert(data.msg)
            }


        };

        set_data();

    }


}