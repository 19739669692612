import React from "react"
import './GetStarted.css'
import { Helmet } from "react-helmet";


export const GetStartedPage = () => {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" name="description" content="Learn how to get started with Golfingbombs, the easiest online golf tournament manager. Play golf tournaments with friends or host large-scale events for free. Explore courses, formats, tournaments, and more." />
        <title>Get Started with Golfingbombs - Easy Golf Tournament Management</title>
        <link rel="canonical" href="https://www.golfingbombs.com/getstarted" />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Get Started with Golfingbombs",
        "description": "Learn how to get started with Golfingbombs, the easiest online golf tournament manager. Play golf tournaments with friends or host large-scale events for free. Explore courses, formats, tournaments, and more.",
        "url": "https://www.golfingbombs.com/getstarted"
      }
    `}
        </script>
      </Helmet>

      <div className="centering-container">
        <div className="gb-background" style={{ width: '95%' }}>
          <h1> An introduction to Golfingbombs </h1>

          <p>
            The easiest golf-tournament manager online. Play golf tournaments with your friends or large scale events for FREE.
          </p>

          <div style={{ marginTop: '30px' }}>
            <h3>
              Courses
            </h3>
            <p>
              We're currently supporting all courses in Sweden, but looking into adding more. If you can't find the course you're looking for, there is always the option to add your own or customize an existing one.
            </p>
          </div>


          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '50px', background: '#F2F2F2', color: 'black', marginLeft: '-25px', marginRight: '-25px', padding: '25px' }}>

            <div style={{ width: 'auto' }}>
              <h3> Formats </h3>

              Can't find the format you're looking for? Let us know! Until then, have you tried them all?

              <br /> <br />
              <ul>
                <li>Strokeplay </li>
                <li>Stableford</li>
                <li>Matchplay</li>
                <li>Matchplay Teams</li>
                <li>Bestball </li>
                <li>Skins</li>
                <li>Bestmall Match</li>
                <li> <b> TOURNAMENTS! </b> </li>
              </ul>

            </div>

          </div>


          <div style={{ marginTop: '50px' }}>
            <h3>
              Tournaments & Matchplay
            </h3>
            
            The best weekend event in a true ryder cup spirit. Try it out!

            <div style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap', marginTop: '50px', background: '#F2F2F2', color: 'black', marginLeft: '-25px', marginRight: '-25px', padding: '25px' }}>

              <div style={{ width: 'auto' }}>
                <img src="/images/getstarted_match_tour.png" alt="Golfingbombs Matchplay Tournament" style={{ width: '100%' }} />
              </div>

            </div>
          </div>


          <div style={{ marginTop: '30px' }}>
            <h3>
              Browser-based, NO-app!
            </h3>

            Select your gamemode and course and you're off!

            <h5 style={{ marginTop: '20px' }}> Advanced settings: </h5>
            <img src="/images/icon_new.png" style={{ width: '30px', backgroundColor: 'white', borderRadius: '5px', padding: '3px' }} /> Create a new course <br /> <br />
            <img src="/images/icon_settings.png" style={{ width: '30px', backgroundColor: 'white', borderRadius: '5px', padding: '3px' }} /> Edit selected course <br /> <br />

            <div style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap', marginTop: '20px', background: '#F2F2F2', color: 'black', marginLeft: '-25px', marginRight: '-25px', padding: '25px' }}>
              <div style={{ width: 'auto', marginBottom: '30px' }}>
                <img src="/images/getstarted_setup_edit.png" style={{ width: '99%' }} />
              </div>
            </div>

          </div>


          <div style={{ marginTop: '50px' }}>
            <h3>
              Leaderboard
            </h3>
            Share it easily with family and friends <br /> <br />

            <h4>
              Display on a tv screen
            </h4>
            [Text]
            <br /> <br />
            <img src="/images/icon_share.png" style={{ width: 'auto', backgroundColor: 'white', borderRadius: '5px' }} /> Share the leaderboard to anyone - no need for an account. <br /> <br />
            <img src="/images/icon_2nd.png" style={{ width: 'auto', backgroundColor: 'white', borderRadius: '5px' }} /> Shows a stableford leaderboard for all gamemodes.

            <div style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap', marginTop: '50px', background: '#F2F2F2', color: 'black', marginLeft: '-25px', marginRight: '-25px', padding: '25px' }}>
              <div style={{ width: 'auto' }}>
                <img src="/images/leaderboard.png" style={{ width: '100%' }} />
              </div>
            </div>
          </div>


          <div style={{ marginTop: '50px' }}>
            <h3>
              Invite a friend without an account
            </h3>

            <ul style={{ listStyleType: 'decimal' }}>
              <li>Press "open slot" and add a user.</li>
              <li> Show the QR-code in the advanced settings. </li>
              <li> Let your friend scan the QR-code or send a link. </li>
              <li> Done </li>
            </ul>

            <div style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap', marginTop: '50px', background: '#F2F2F2', color: 'black', marginLeft: '-25px', marginRight: '-25px', padding: '25px' }}>

              <div style={{ width: 'auto' }}>
                <img src="/images/addfriend.png" style={{ width: '100%' }} />
              </div>

            </div>
          </div>


          <div style={{ marginTop: '50px' }}>
            <h3>
              MS Excel - the holy grail for tournaments.
            </h3>
            Hosting events has never been this easy.
            <ul style={{ listStyleType: 'decimal' }}>
              <li>Download our template</li>
              <li>Setup players and tee times</li>
              <li>Upload excel file</li>
              <li>Print the QR-code, place it on the first tee </li>
              <li>Let each player scan the QR-code and choose player/tee time</li>
            </ul>
            Please note this is a single use account for each competition.

            <div style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap', marginTop: '50px', background: '#F2F2F2', color: 'black', marginLeft: '-25px', marginRight: '-25px', padding: '25px' }}>
              <div style={{ width: 'auto', marginBottom: '20px' }}>
                <img src="/images/excel.png" style={{ width: '99%' }} />
              </div>
              <div style={{ width: 'auto' }}>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}