import { useState } from 'react';

function useToken() {

  function isAuth(token) {
    if (!token && token !== "" && token !== undefined) {
      return true
    } else {
      return false
    }
  }

  function getToken() {
    const userToken = localStorage.getItem('token');
    return userToken && userToken
  }

  const [token, setToken] = useState(getToken());

  function saveToken(userToken) {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  function removeToken() {
    localStorage.removeItem("token");
    setToken(null);
  }

  return {
    setToken: saveToken,
    token,
    removeToken,
    isAuth
  }

}

export default useToken;