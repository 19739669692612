import React from "react";
import './LandingPage.css'
import CookieConsent from "react-cookie-consent";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const LandingPage = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Golfingbombs - Free golf score app for tournaments and events</title>
        <meta charSet="utf-8" name="description" content="Golfingbombs is a free golf score app, offering multi-day tournaments, team & individual play, and more. Join now for easy golf scorekeeping and exciting game modes." />
        <link rel="canonical" href="https://www.golfingbombs.com" />
        <script type="application/ld+json">
          {`{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Golfingbombs Landing Page",
        "description": "Golfingbombs is a free golf score app, offering multi-day tournaments, team & individual play, and more. Join now for easy golf scorekeeping and exciting game modes.",
        "url": "https://www.golfingbombs.com/"
      }`}
        </script>
      </Helmet>

      <img className="landingpage-header-img" src="/images/golf-header.avif" alt="Golfingbombs Header Image" />
      <div className="landingpage-grey-box">
        <div className="fp-header-div">
          <h1>Welcome to Golfingbombs</h1>
          <h2 className="fp-2ndheader"> Golf tournaments made easy.</h2>

          <h3 style={{ fontSize: '12pt' }}> A free golfscore website, with multi day tournaments,
            team & individual play and a lot more completely free.
          </h3>

        </div>

        <div className="centering-container">
          {props.isAuth ? (
            <button> START GAME </button>
          ) : (
            <button style={{ fontSize: '16pt', paddingLeft: '20px', paddingRight: '20px', color: 'black', backgroundColor: 'white' }}
              onClick={() => { navigate('/getstarted') }}
            > GET STARTED </button>
          )}

        </div>

        <div className="landingpage-beer-img">
          <img id="landingpage-beer-img" src="/images/golf-tournament-beer.avif"></img>
        </div>

        <div className="landingpage-white-box">
          <div className="spacer"> </div>

          <div className="landingpage-container">
            <h3> GOLFWEEKEND WITH THE CREW </h3>
            <p >
              We've all been there, it's 08:03 and you've just arrived late at the parking lot.
              You barely remember who you're playing with from last night, but it's here Golfingbombs saves the day.

              It's boss, and it will keep track to whom you're gonna loose your money to.

              So get out there and hit  massive bombs and let the scorekeeping to us!
              <br />
              <br />
              <br />

            </p>
            <h3> INFORMATION & NEWS </h3>
            <p>
              2023-11-16 Beta Release:  <br />
              Basic features, there are some issues left to fix.
              <br />
              <br />
              Working game modes:
              <br />
            </p>

            <ul>
              <li>Stableford</li>
              <li>Stroke Play</li>
              <li>Matchplay</li>
              <li>Team Matchplay</li>
              <li>Multi-round tournaments</li>
            </ul>
            <p>

              We're currently supporting all courses and tees in Sweden.
              But you're welcome to add your own custom course. More is coming soon!

            </p>
            <br />

          </div>
        </div>

        <div className="landingpage-container" style={{ height: 'auto', paddingTop: '30px' }}>
          <h3> How to get started</h3>
          <p>
            It's super simple: register, setup a round and GO!
          </p>

          <img className="phones-image" src="/images/phones.avif"></img>

        </div>

        <div className="footer" >
          Provided to you by passionate golfers. <br />
          <span >
            Copyright © 2023 Golfingbombs
          </span>
          <div className="terms-of-service" style={{marginTop:'20px'}}>
            <p>
              These Terms of Service ("TOS") govern your use of www.golfingbombs.com ("the Website"), owned and operated by Golfingbombs AB.
               By accessing or using the Website, you agree to comply with these <a href="/tos">terms</a>.
               </p>
          </div>
        </div>

      </div>

      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="CookieBomb"
        style={{ background: "rgba(0,0,0,0.9)" }}
        buttonStyle={{ backgroundColor: 'white', color: "black", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience. {" "}
        <span style={{ fontSize: "10px" }}></span>
      </CookieConsent>
    </>
  )
}