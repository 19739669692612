import React from 'react'
import { useState, useEffect, useRef } from 'react';
import './Scorecard.css'
import ScorecardExpanded from './ScorecardExpanded'
import MoonLoader from "react-spinners/MoonLoader";

const ScorecardCard = (props) => {

    const [isExpanded, setIsExpanded] = useState('')
    const [strokes, setStrokes] = useState(props.strokes)
    const [expandedData, setExpandedData] = useState()
    const [isLoading, setLoading] = useState(true);

    const data = props.data
    const inputRef = useRef(null);

    function handleExpandScorecard() {

        if (isExpanded) {
            setIsExpanded(false)
        } else {

            const get_data = async () => {

                const data = await (
                    await fetch('/api/scorecard_expanded?' + new URLSearchParams({
                        uuid: props.data.uuid,
                        round_uuid: props.data.round_uuid
                    }), {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + props.token
                                }   
                            })
                ).json();

                setExpandedData(data)
                setLoading(false)
            
            };
            get_data();
            setIsExpanded(true)
        }
    }

    function handleOnInput(e) {
        let value = e.target.value

        setStrokes(value)

        var body = {
            'uuid': data.uuid,
            'strokes': value,
            'index': props.index
        };

        props.addStrokes(body)

        //If we're starting with an input as a 1, don't change. 
        if((value !== '1') && (e.nativeEvent.inputType !== 'deleteContentBackward')){
            props.nextInput(props.index)
        }
    }

    function handleNextInput(e){
        //If we're moving out from the scorecardcards, change hole.
        try{
            if(e.relatedTarget.id === 'inpt-next'){
                props.nextHole()            
            }
        }catch(TypeError){

        }
    }

    //?? What is this for.
    useEffect(() => {
        if(inputRef.current !== null){
            inputRef.current.focus();
            inputRef.current.click();
        }        
    }, []);

    return (
        
        <div className='scorecard-container' onClick={() => handleExpandScorecard()}>
            <div className='scorecard-player'>
                <div className='scorecard-nickname' >
                    <span className='p1'>  {data.nickname}   </span>
                    <span className='p2'>  {data.shcp} </span>
                </div>
                <div className='scorecard-extra' > {data.extra_strokes} </div>
                <div className='scorecard-score' style={{ color: data.score < 0 ? 'red' : '' }}> {data.score} </div>
                <div className='scorecard-prev-strokes'>{data.prev_strokes} <sub> {data.prev_sub} </sub> </div>
                <div className='scorecard-strokes'>
                    <input id={props.index} className='scorecard-input' type='tel' placeholder=""
                        ref={props.index === 0 ? inputRef : null}
                        autoComplete="off"
                        value={strokes } 
                        onChange={(e) => handleOnInput(e)}  
                        style={{ borderBottomRightRadius: isExpanded ? '0px' : '15px'}}
                        
                        //Don't expand the scorecard when clicking on the input.
                        onClick={(e) => {e.stopPropagation()}}
                        
                        //This will move to next hole
                        onBlur={(e) => { handleNextInput(e) }}
                    >
                    </input>
                </div>
            </div>

            <div className='scorecard-player-expanded' style={{ display: isExpanded ? '' : 'none' }}>

                {isExpanded ? (
                    (() => {
                        if (isLoading) {
                            return <>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
                                </div>
                            </>;
                        } else {
                            return (
                                <ScorecardExpanded data={expandedData} />
                            )
                        }
                    })()

                ) : (
                    null
                )}

            </div>

        </div>

    )
}

export default ScorecardCard

