import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {isMatchPlay, isWhiteBackGround} from '../misc/checks'
import Modal from './Modal';
import { ROUND_STATE } from '../misc/enums'

export function SortableItem(props) {
  
  const [openModal, setOpenModal] = useState(false)
  const [showOpenSlot, setShowOpenSlot] = useState(false)
  const [showHcpTee, setShowHcpTee] = useState(false)
  
  const player = props.player
  const game = props.game

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: player.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: isWhiteBackGround(game.game_format) ? 'none' : ''
  };

  const vsStyle = {
    backgroundColor: isMatchPlay(game.game_format) ? '' : 'transparent',
    color: isMatchPlay(game.game_format) ? '' : 'transparent'
  }

  return (
    <>
      <div className='player-container noselect' ref={setNodeRef} style={style} {...attributes} {...listeners}
        onClick={() => {
          
          if(props.lobbyState === ROUND_STATE.LIVE){
            return;
          }

          if (player.name !== 'Open Slot' && !props.locked) {
            setShowHcpTee(true)
            setOpenModal(true)
          } else if (player.name === 'Open Slot') {
            // Add player modal
            setShowOpenSlot(true)
            setOpenModal(true)
          }
        }}
      >        
        <span className='player-name' style={{ color: player.name === 'Open Slot' ? '#d9d6d9' : '' }}>{player.name}</span>

        {player.hcp !== "" ? (
          <>
            <div className='tee-hcp-container' > {player.tee} | {player.hcp < 0 ? '+' + -1*player.hcp: player.hcp} | {player.shcp} </div>
          </>
        ) : (
          <div className='tee-hcp-container'></div>
        )}

      </div>

      {props.index % 2 === 0 ? (
        <div className='vs-icon noselect' style={vsStyle}> VS </div>
      ) : ("")}

      <Modal
        hcp={player.hcp}
        open={openModal}
        setUpdate={()=> props.setUpdate()}
        onClose={() => {
          setOpenModal(false);
          setShowOpenSlot(false)
          setShowHcpTee(false)
        }}
        openSlot={showOpenSlot}
        hcpAndTee={showHcpTee}
        course_uuid={game.course_uuid}
        player_uuid={player.uuid}
        tee={{label: player.tee, value: player.tee_uuid}}
        index={props.index}
      /> 

    </>
  );
}

