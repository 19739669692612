import React from 'react'
import { useState, useEffect } from 'react';
import MoonLoader from "react-spinners/MoonLoader";
import { isBestBall } from '../misc/checks';

const ScorecardExpanded = (props) => {

  const numbers = [...Array(19).keys()]

  function formatScore(strokes, par) {

    if (isBestBall(props.game_format)) {
      if (strokes < 0) {
        return birdieStyle
      } else if (strokes > 0) {
        return bogeyStyle
      }
    } else {
      if (strokes >= (par + 2)) {
        return doubleBogeyStyle
      } else if (strokes == (par + 1)) {
        return bogeyStyle
      } else if (strokes == par) {
        return parStyle
      } else if (strokes == (par - 1)) {
        return birdieStyle
      } else if (strokes == (par - 2)) {
        return eagleStyle
      }
    }

  }

  function fixScore(index) {

    var strokes = props.data.score.strokes[index]
    var strokes_sub = props.data.score.strokes_sub[index]
    var par = props.data.course.par[index]

    const text =
      <span style={formatScore(strokes, par)}> {strokes !== 0 ? strokes : ''}
        <sub> {strokes !== 0 ? strokes_sub : ''}
        </sub>
      </span>

    return text
  }

  return (

    <>
      <table>
        <tbody className='normal'>
          <tr className='sc-row'>
            <td className='sce-cell-header'> Hole </td>
            {numbers.slice(1, 10).map((item, index) =>
              <td className='sce-cell' key={index}> {item} </td>
            )}
            <td className='sce-cell'></td>
          </tr>
          <tr>
            <td className='sce-cell-header'> Idx </td>
            {props.data.course.hcp_index.slice(0, 9).map((item, index) =>
              <td className='sce-cell' key={index}> {item} </td>
            )}
          </tr>
          <tr>
            <td className='sce-cell-header'> Par </td>
            {props.data.course.par.slice(0, 9).map((item, index) =>
              <td className='sce-cell' key={index}> {item} </td>
            )}
            <td style={{ textAlign: 'center' }}> Out </td>
          </tr>
          <tr>
            <td className='sce-cell-header'>  </td>
            {numbers.slice(1, 10).map((item, index) =>
              <td className='sce-cell' key={index}>
                {fixScore(index)}
              </td>
            )}

            <td className='sce-cell'>
              <span > {props.data.out_strokes}
                <sub> {props.data.out_sub}
                </sub>
              </span>
            </td>
          </tr>

          <tr>
            <td className='sce-cell-header'> Hole </td>
            {numbers.slice(10, 19).map((item, index) =>
              <td className='sce-cell' key={index}> {item} </td>
            )}
            <td className='sce-cell'></td>
          </tr>

          <tr>
            <td className='sce-cell-header'> Idx </td>
            {props.data.course.hcp_index.slice(9, 18).map((item, index) =>
              <td className='sce-cell' key={index}> {item} </td>
            )}
          </tr>

          <tr>
            <td className='sce-cell-header'> Par </td>
            {props.data.course.par.slice(9, 18).map((item, index) =>
              <td className='sce-cell' key={index}> {item} </td>
            )}
            <td style={{ textAlign: 'center' }}> In </td>
          </tr>

          <tr>
            <td className='sce-cell-header'>  </td>
            {numbers.slice(10, 19).map((item, index) =>
              <td className='sce-cell' key={index}>
                {fixScore(index + 9)}
              </td>
            )}

            <td className='sce-cell'>
              <span > {props.data.in_strokes}
                <sub> {props.data.in_sub}
                </sub>
              </span>
            </td>

          </tr>
        </tbody>
      </table>
    </>
  )
}

export default ScorecardExpanded

const doubleBogeyStyle = {
  padding: '2px 5px 2px 5px',
  backgroundColor: "rgb(148, 165, 201)"
}

const bogeyStyle = {
  padding: '2px 5px 2px 5px',
  backgroundColor: "rgb(209, 223, 255)"
}

const parStyle = {
}

const birdieStyle = {
  padding: '2px 5px 2px 5px',
  borderRadius: "50%",
  backgroundColor: "rgb(255, 93, 93)"
}

const eagleStyle = {
  padding: '2px 5px 2px 5px',
  borderRadius: "50%",
  backgroundColor: "rgb(238, 255, 0)"
}
