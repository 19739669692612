import { useEffect, useState } from "react";
import { useRef } from "react";

function RegWorker(token) {

    const applicationServerPublicKey = "BFRdSjkxyTHMlYMvuN2HKXIOdzmhRmYI_EOdvLUHPYUA7ZRy_olLIPmNbZo0xzHd2gb7sRcNyZPo3b4NEiocQtM";

    let swRegistration = null;
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [button, setButton] = useState(false);
    

    function urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    function updateSubscriptionOnServer(subscription) {

        return fetch('/api/push-subscriptions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                subscription_json: JSON.stringify(subscription)
            })
        });

    }

    function subscribeUser() {
        const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
        
        console.log(swRegistration)
        swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
        })
            .then(function (subscription) {
                console.log('Subscribing...');

                updateSubscriptionOnServer(subscription);
                setButton(true)
                setIsSubscribed(true)

            })
            .catch(function (err) {
                console.log('Failed to subscribe the user: ', err);
            });
    }


    function unSubscribeUser() {
        swRegistration.pushManager.getSubscription().then((subscription) => {
            subscription
                .unsubscribe()
                .then((successful) => {
                    setIsSubscribed(false)
                    // isSubscribed = false;
                    setButton(false);
                    // You've successfully unsubscribed
                })
                .catch((e) => {
                    // Unsubscribing failed
                });
        });
    }

    function init() {
        swRegistration.pushManager.getSubscription()
            .then(function (subscription) {

                let sub = !(subscription === null);
                updateSubscriptionOnServer(subscription);

                if (sub) {
                    // console.log('User IS subscribed.');
                    setButton(true);
                    setIsSubscribed(true)

                } else {
                    // console.log('User is NOT subscribed.');
                    setButton(false);
                    setIsSubscribed(false)

                }
            });
    }

    function clickButton() {
        // if(window.Notification?.permission === "granted"){      

        Notification.requestPermission();
        
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            console.log('Service Worker and Push is supported');

            navigator.serviceWorker.register('/static/sw.js')
                .then(function (swReg) {
                    console.log('Service Worker is registered', swReg);
                    swRegistration = swReg;
                    init();

                    if (isSubscribed) {
                        unSubscribeUser();
                    } else {
                        subscribeUser();
                    }
                })
                .catch(function (error) {
                    console.error('Service Worker Error', error);
                });
        } else {
            console.log('Push messaging is not supported');
            alert("Notifications not supported on your device")
        }
    }

    function isSub() {

        if (button) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            console.log('Service Worker and Push is supported');

            navigator.serviceWorker.register('/static/sw.js')
                .then(function (swReg) {
                    console.log('Service Worker is registered', swReg);
                    swRegistration = swReg;
                    init();
                })
                .catch(function (error) {
                    console.error('Service Worker Error', error);
                });
        } else {
            console.log('Push messaging is not supported');
        }

    }, []);

    return {
        clickButton,
        isSub
    }
}

export default RegWorker;