
export const SelectStyle = {
  container: provided => ({
    ...provided,
    marginTop: '5px',
    // width: '220px',
    // maxWidth: '90%'
  }),
  control: base => ({
    ...base,
    width: '220px',
    maxWidth: '100%',
    }),
  option: base => ({
    ...base,
    width: '220px'
  }),
  menu: base => ({
    ...base,
    width: '220px',
    maxWidth: '100%'
  }),
}

export const miniStyle = {
  container: provided => ({
    ...provided,
    marginTop: '5px',
    width: '20%',
  }),
  // control: base => ({
  //   ...base,
  //   minWidth: '70px',
  //   height: '38px !important'
  // }),
  // placeholder: (provided, state) => ({
  //   ...provided,
  //   fontSize: "12pt",
  //   color: "#303030",
  //   background: 'none',
  //   width: '220px',
  // }),
  // option: (provided, state) => ({
  //   ...provided,
  //   fontSize: "12pt",
  //   width: '220px',
  // }),
  control: base => ({
    ...base,
    maxWidth: '100%',
    height: '38px !important'
    }),
  option: base => ({
    ...base,
  }),
  menu: base => ({
    ...base,
    maxWidth: '100%',
  }),
}

export const SelectStyleFull = {
  container: provided => ({
    ...provided,
    marginTop: '5px',
    width: '100%'
  }),
  control: base => ({
    ...base,
    // border: 0,
    width: '100%',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12pt",
    color: "#303030",
    background: 'none',
    width: '100%'
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "12pt",
    width: '100%'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "12pt",
    width: '100%'
  })
}