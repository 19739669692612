
export const GAMEMODE = {
    STABLEFORD : "e6dd277e-c73b-4559-a62d-33d154dee87c",
    STROKEPLAY : "9edc514b-05aa-4caa-a3ad-5eba2cd42b73",
    MATCHPLAY : "7959e31a-9d5b-455a-ab87-23d5ad07d8ee",
    MATCHPLAYTEAMS : "a1a1146e-93d4-4748-8a13-1293ae65c5f5",
    BESTBALL: "c5d95e2e-2414-427a-8b99-3bb130c56b3d",
    SKINS: "f186d02c-a085-4dd0-a2e1-8d4ca0be3672",
    BESTBALLMATCH: "9c0f23e1-7f33-4732-9f5f-3e5124f1386f"
}

export const ROUND_STATE = {
    INLOBBY : 1,
    LIVE : 2,
    FINISHED : 3,
    EDITING : 4
}

export const ROUNDSTATE = {
    INLOBBY : 1,
    LIVE : 2,
    FINISHED : 3,
    EDITING : 4
}

export const ERRORCODES = {
    SUCCESS : 1,
    FAILED : 2,
    NOACTIVEROUND : 3,
    EMPTY : 69,
    NA : 99,
}

export const gamemodes = [
    { value: "e6dd277e-c73b-4559-a62d-33d154dee87c", label: "Stableford" },
    { value: "9edc514b-05aa-4caa-a3ad-5eba2cd42b73", label: "Strokeplay" },
    { value: "7959e31a-9d5b-455a-ab87-23d5ad07d8ee", label: "Matchplay" },
    { value: "a1a1146e-93d4-4748-8a13-1293ae65c5f5", label: "Team Matchplay" },
    { value: "c5d95e2e-2414-427a-8b99-3bb130c56b3d", label: "Bestball" },
    { value: "f186d02c-a085-4dd0-a2e1-8d4ca0be3672", label: "Skins" },
    { value: "9c0f23e1-7f33-4732-9f5f-3e5124f1386f", label: "Bestball Match" }
]


export const gamemodes_ = [
    { id: "e6dd277e-c73b-4559-a62d-33d154dee87c", mode: "Stableford" },
    { id: "9edc514b-05aa-4caa-a3ad-5eba2cd42b73", mode: "Strokeplay" },
    { id: "7959e31a-9d5b-455a-ab87-23d5ad07d8ee", mode: "Matchplay" },
    { id: "a1a1146e-93d4-4748-8a13-1293ae65c5f5", mode: "Matchplay_Teams" },
    { id: "c5d95e2e-2414-427a-8b99-3bb130c56b3d", mode: "Bestball" },
    { id: "f186d02c-a085-4dd0-a2e1-8d4ca0be3672", mode: "Skins" },
    { id: "9c0f23e1-7f33-4732-9f5f-3e5124f1386f", mode: "BestBallMatch" }
]


// export const isStrokePlay = function (input) {

export function getGameformatName(uuid){

    for (let i = 0; i < gamemodes.length; i++) {
        if(gamemodes[i].value === uuid){
            return gamemodes[i].label
        }
    }

}


