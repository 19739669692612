import React from "react";
import { useEffect, useState } from "react";
import './SetupPage.css'
import { useNavigate } from "react-router-dom"
import MoonLoader from "react-spinners/MoonLoader";
import { SelectGolfClubCourseTee } from '../components/SelectCourseTee'
import useToken from '../components/useToken'
import { isBestBall, isBestBallMatch, isMatchPlay, isSkins, isStableford, isStrokePlay } from "../misc/checks";
import { TEXTS } from "../misc/utils";
import { gamemodes_ } from "../misc/enums";

export const SetupPage = (props) => {
  const navigate = useNavigate();
  const { token } = useToken();

  const [gameFormats, setAvailableGameFormats] = useState('')
  const [isLoading, setLoading] = useState(true);

  const [gamemode, setGameMode] = useState('')
  const [course, setCourse] = useState('')
  const [tee, setTee] = useState('')

  useEffect(() => {

    setAvailableGameFormats(gamemodes_);
    const selectedGamemode = sessionStorage.getItem('gamemode');

    if (selectedGamemode) {
      setGameMode(selectedGamemode)
    }

    setLoading(false)
  });

  if (isLoading) {
    return <>
      <div className='centering-loading'>
        < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
      </div>
    </>;
  }

  const stringArray =
    ["url('/images/stableford.png')",
      "url('/images/strokeplay.png')",
      "url('/images/matchplay.png')",
      "url('/images/matchplayteam.png')",
      "url('/images/bestball.png')",
      "url('/images/skins.png')",
      "url('/images/bestball_taliban.png')",
    ]

  return (
    <>
      <div className="gameformat-container">
        <div className="gameformat-track">

          {gameFormats.map((item, index) =>
            <div className='gameformat-box'
              id={item.id}
              style={{ backgroundImage: stringArray[index], filter: gamemode === item.id ? "grayscale(0%)" : "grayscale(100%)" }}
              key={index}
              onClick={(e) => handleGameModeSelect(e.target)}>
            </div>
          )}

        </div>
      </div>

      <div className="gamemode-info" style={{ display: isStableford(gamemode) ? '' : 'none' }}>
        <h4>Stableford</h4>
        {TEXTS.STABLEFORD}
      </div>

      <div className="gamemode-info" style={{ display: isStrokePlay(gamemode) ? '' : 'none' }}>
        <h4>Strokeplay</h4>
        {TEXTS.STROKEPLAY}
      </div>

      <div className="gamemode-info" style={{ display: isMatchPlay(gamemode) ? '' : 'none' }}>
        <h4>Matchplay</h4>
        {TEXTS.MATCHPLAY}
      </div>

      <div className="gamemode-info" style={{ display: isBestBall(gamemode) ? '' : 'none' }}>
        <h4>Bestball</h4>
        {TEXTS.BESTBALL}
      </div>

      <div className="gamemode-info" style={{ display: isBestBallMatch(gamemode) ? '' : 'none' }}>
        <h4>Bestball Match</h4>
        {TEXTS.BESTBALLTALIBAN}
      </div>

      <div className="gamemode-info" style={{ display: isSkins(gamemode) ? '' : 'none' }}>
        <h4>Skins</h4>
        {TEXTS.SKINS}
      </div>

      <div className="select-course-container">
        <h2> SELECT GOLF COURSE & TEE </h2>

        <div style={{ height: 'auto' }}>
          <SelectGolfClubCourseTee
            setCourse={(e) => setCourse(e.value)}
            setTee={(e) => setTee(e.value)}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <button className='rad-button' onClick={() => { handleStartButton() }} style={{ marginLeft: 'auto', marginRight: '0px', width: '100px', marginTop: '20px'}}> GO </button>
        </div>

      </div>

    </>
  )

  function handleGameModeSelect(e) {
    setGameMode(e.id)
    sessionStorage.setItem('gamemode', e.id);
  }

  function handleStartButton() {

    if (gamemode === "") {
      alert("Please select gamemode")
      return
    }

    if (course === "") {
      alert("Please select course")
      return
    }

    if (tee === "") {
      alert("Please select tee")
      return
    }

    var body = {
      "name": "",
      "gamemode": gamemode,
      "course": course,
      "tee": tee,
    };

    const set_data = async () => {
      await (
        await fetch('/api/round/new', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify(body)
        })
      ).json()

      props.setUpdate()
      navigate("/editround")

    };
    set_data();
  }
}
