import React from 'react';
import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import LeaderboardCard from '../components/LeaderboardCard';
import { useNavigate } from "react-router-dom"
import MoonLoader from "react-spinners/MoonLoader";
import { isSkins, isStrokePlay } from '../misc/checks';
import { GAMEMODE } from '../misc/enums'


export const LeaderboardPageStandard = (props) => {
    const navigate = useNavigate();
    const [link, setLink] = useState('');

    function shareLink() {

        const get_data = async () => {
            const data = await (
                await fetch('/api/leaderboard_link', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.token
                    }
                })
            ).json();

            setLink(data.link)

            const el = document.createElement('textarea');
            el.value = data.link;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            alert(data.link + ' copied to clipboard!')

        };

        get_data();
    }

    return (
        <>
            <div className='score-container'>

                <div className='scorecard-header-container'>
                    
                    <div className='header-three' style={{marginRight: 'auto', width:'100%'}}>
                        <span className='header-item' style={{width:'100%', textAlign: 'left', paddingLeft:'5px'}} >  {props.data.name} </span>
                    </div>

                    <div className='header-three'>
                        <span className='header-item'> {props.data.multiround_num > 1 ? ('Today') : ''} </span>
                    </div>

                    <div className='header-three' >
                        {isSkins(props.gameFormat) && !props.secondary ?
                            <span className='header-item' > Skins </span>
                            :
                            <span className='header-item' > To Par </span>
                        }
                    </div>

                    <div className='header-three' >
                        <span className='header-item' > Thru </span>
                    </div>
                </div>

                {props.leaderboard.map((item, index) =>
                    <LeaderboardCard
                        key={item.uuid}
                        data={item}
                        index={index}
                        multiround_num={props.data.multiround_num}
                        round_uuid={props.round_uuid}
                        game_format={props.gameFormat}
                        secondary={props.secondary}
                    />

                )}

                <div style={{ marginBottom: '50px' }}>

                    {link === '' ? <img className="button-share" src="/images/icon_share.png" onClick={() => shareLink()}></img>
                        : <div style={{ backgroundColor: 'white', padding: '5px', textAlign: 'center', marginRight: '20px', borderRadius: '0px 5px 5px 0px' }}> {link} </div>}
                    

                    {/* If we're doing strokeplay show secondary icon. 
                    But don't show it if we're already at the 2nd leaderboard. 
                    */}
                    
                    {isStrokePlay(props.gameFormat) && !props.secondary ?
                        <img className="button-share" alt='' src="/images/icon_2nd.png" onClick={() => { navigate("/leaderboard_2nd?round_uuid=" + props.round_uuid) }}></img>
                        : null}

                    {isSkins(props.gameFormat) && !props.secondary ?
                        <img className="button-share" alt='' src="/images/icon_2nd.png" onClick={() => { navigate("/leaderboard_2nd?round_uuid=" + props.round_uuid) }}></img>
                        : null}

                </div>


            </div>
  
            {props.round_uuid ?
                <div className="footer-switch" onClick={() => navigate("/scorecard")}  >
                    <img src="/images/icon_switch.png" alt="" style={{ marginRight: '15px' }} />
                    Scorecard
                </div>
                : null
            }
      
        </>

    )
}

