import { GAMEMODE, ROUNDSTATE } from "./enums"


export const isStandardLeaderboard = function (input) {
    
    if (input === GAMEMODE.STABLEFORD || input === GAMEMODE.STROKEPLAY || input === GAMEMODE.SKINS) {
        return true
    }

    return false
}

export const isWhiteBackGround = function (input) {

    if (input === GAMEMODE.STABLEFORD || input === GAMEMODE.STROKEPLAY || 
        input === GAMEMODE.MATCHPLAY || input === GAMEMODE.SKINS ||
        input === GAMEMODE.BESTBALL ) {
        return true
    }

    return false
}

export const isMatchPlay = function (input) {

    if (input === GAMEMODE.MATCHPLAY || input === GAMEMODE.MATCHPLAYTEAMS) {
        return true
    }

    return false
}

export const isBestBall = function (input) {

    if (input === GAMEMODE.BESTBALL) {
        return true
    }

    return false
}

export const isStrokePlay = function (input) {

    if (input === GAMEMODE.STROKEPLAY) {
        return true
    }

    return false
}

export const isStableford = function (input) {

    if (input === GAMEMODE.STABLEFORD) {
        return true
    }

    return false
}

export const isBestBallMatch = function (input) {

    if (input === GAMEMODE.BESTBALLMATCH) {
        return true
    }

    return false
}

export const isSkins = function (input) {

    if (input === GAMEMODE.SKINS) {
        return true
    }

    return false
}


const isMatchPlayTeams = function (input) {

    if (input === GAMEMODE.MATCHPLAYTEAMS) {
        return true
    }

    return false
}


const isLive = function (input) {
    if (input === ROUNDSTATE.LIVE) {
        return true
    }

    return false
}