import React from "react";
import PreviousRoundList from '../components/PreviousRoundList';

export const RoundsPage = (props) => {

  return (
    <>
      <div className="centering-container">
        <div className="round-container">
          <PreviousRoundList
            token={props.token}
            setUpdate={() => props.setUpdate()} />
        </div>
      </div>
    </>

  )
}
