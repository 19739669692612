import React from 'react'
import { useState, useEffect } from 'react'
import './Modal.css'
import Friendslist from './Friendslist'
import { useRef } from "react";
import useToken from '../components/useToken'
import { SelectStyle } from "../misc/styles";
import Select from 'react-select';
import { SelectTeeStandalone } from './SelectCourseTee';

const Modal = (props) => {
    const { token } = useToken();
    const [playerHcp, setPlayerHcp] = useState(props.hcp)
    const [playerTee, setPlayerTee] = useState(props.tee)
    const [showTeeOpenSlot, setShowTeeOpenSlot] = useState(false)
    const [genderOpenSlot, setGenderOpenSlot] = useState()

    const imageRef = React.useRef();

    const genderRef = useRef()
    const nameRef = useRef()
    const hcpRef = useRef()

    if (!props.open) return null

    return (

        <div className='overlay' onClick={props.onClose}>
            <div className='modal-container' onClick={(e) => {
                e.stopPropagation()
            }}>

                {props.openSlot ?
                    <>
                        <div style={{ width: '250px'}}>

                            <h2> Generic Player </h2>

                            <div className="form-ctrl">
                                <label>Name</label>
                                <input type='text' placeholder=""
                                ref={nameRef}
                                ></input>
                            </div>

                            <div className="form-ctrl">
                                <label>HCP</label>
                                <input type='text' placeholder=""
                                ref={hcpRef}                 
                                ></input>
                            </div>

                            <div className="form-ctrl">
                                <label>Gender</label>
                                <Select
                                    style={{ width: '200px' }}
                                    className="input-setup"
                                    options={[{ value: 1, label: "Male" }, { value: 0, label: "Female" }]}
                                    ref={genderRef}
                                    styles={SelectStyle}
                                    isSearchable={false}
                                    onChange={(e) => {
                                        setShowTeeOpenSlot(true)
                                        setGenderOpenSlot(e.value)
                                    }}
                                />
                            </div>

                            {showTeeOpenSlot ?
                                <div className="form-ctrl">
                                    <label>Tee</label>

                                    <SelectTeeStandalone
                                        setTee={(e) => { setPlayerTee(e) }}
                                        course_uuid={props.course_uuid}
                                        player_uuid={props.player_uuid}
                                        tee={props.tee}
                                        gender={genderOpenSlot}
                                    />

                                </div>
                                : null}

                        </div>

                        <button onClick={() => addPlayer()} style={{width:'50px', marginTop:'20px'}}> Add </button>

                        <h2 style={{marginTop:'50px'}}>Friends</h2>
                        
                        <Friendslist
                            addButton={true}
                            setUpdate={() => props.setUpdate()}
                        />

                        <div className="centering" style={{marginTop:'20px'}}>
                            <button onClick={props.onClose} style={{fontSize: '16pt'}}> OK </button>
                        </div>
                    </>
                    : null
                }

                {props.hcpAndTee ?
                    <>
                        <div className='modal-settings-container'>
                            <div className="form-ctrl">
                                <label>HCP</label>
                                <input type='text' placeholder=""
                                    style={{ width: '220px' }}
                                    value={playerHcp < 0 ? '+' + -1*playerHcp : playerHcp || ''}
                                    onChange={(e) => setPlayerHcp(e.target.value)}
                                ></input>
                            </div>
                           
                            <SelectTeeStandalone
                                setTee={(e) => {setPlayerTee(e)}}
                                course_uuid={props.course_uuid}
                                player_uuid={props.player_uuid}
                                tee={props.tee}
                            />

                            <button style={{width: '100px', marginTop: '10px'}} onClick={()=>{updatePlayer()}}> Save </button>

                        </div>
                        
                        <button style={{ marginTop: '50px', width: '100%' }} onClick={() => removePlayer(props.player_uuid)}> Remove player </button>
                    </>
                    : null}

                {props.qrCode ?
                    <>
                        {getQrImage()}
                        <div className="centering">
                            <img className='qr-image' ref={imageRef}></img>
                        </div>
                        {props.qrCodeLink}
                    </>

                    : null} 

                <div className="modal-close" onClick={props.onClose} > X </div>
            </div>
        </div>
    )

    function getQrImage() {

        const get_data = async () => {

            const data = await (
                await fetch('/api/lobby/qr', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
            ).blob()

            let objectURL = URL.createObjectURL(data)
            imageRef.current.src = objectURL;

        };

        get_data()
    }

    function updatePlayer() {

        var body = {
            "uuid": props.player_uuid,
            "tee_uuid": playerTee,
            "hcp": playerHcp
        };

        const set_data = async () => {
            const data = await (
                await fetch('/api/lobby/update_player', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            props.onClose()
            props.setUpdate()

        };
        set_data();
    }

    function addPlayer() {

        var body = {
            "nickname": nameRef.current.value,
            "hcp": hcpRef.current.value,
            "gender": genderRef.current.getValue()[0].value,
            "tee": playerTee,
            "index": props.index
        };
        
        const set_data = async () => {
            const data = await (
                await fetch('/api/lobby/add_player', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            if (data.msg === 'Success') {
                window.location.reload(false);
            } else {
                alert(data.msg)
            }

        };
        set_data();
    }

    function removePlayer(uuid) {

        var body = {
            "uuid": uuid
        };

        const set_data = async () => {
            const data = await (
                await fetch('/api/lobby/remove_player', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()
            props.setUpdate()
            props.onClose()
        };
        set_data();

    }
}


export default Modal

