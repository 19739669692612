import React from "react";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom"

export const ResetPasswordPage = (props) => {

  const [msg, setMsg] = useState('')
  const emailRef = useRef()

  return (
    <>
      <div className="centering-container">
        <div className="gb-background" style={{maxWidth: '300px'}}>
          <form onSubmit={() => handleForgotPassword()}>

            <div className="form-ctrl">
              <label> Email </label>
              <input type='email' placeholder=""
                ref={emailRef}
              ></input>
            </div>

            <input type='submit' style={{marginTop: '20px'}}></input>

            <div>{msg}</div>

          </form>

        </div>
      </div>
    </>
  )


  function handleForgotPassword() {

    var body = {
      "email": emailRef.current.value,
    };

    const set_data = async () => {

      const data = await (
        await fetch('/api/forgot_password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          },
          body: JSON.stringify(body)
        })
      ).json()

      setMsg(data.msg)

    };

    set_data();

  }




}