import React from 'react';
import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import {isMatchPlay, isBestBall, isStandardLeaderboard, isBestBallMatch} from '../misc/checks'
import { LeaderboardPageMatch } from './LeaderboardPageMatch';
import { LeaderboardTeamsPage } from './LeaderboardTeamsPage';
import { LeaderboardPageStandard } from './LeaderboardPageStandard';

export const LeaderboardPage = (props) => {

    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [update, setUpdate] = useState(false)

    //Firest check if we've got en external request.
    const [searchParams, setSearchParams] = useSearchParams();
    var round_uuid = searchParams.get("round_uuid")

    if (round_uuid === null) {
        round_uuid = props.round_uuid
    }

    setTimeout(function(){
        setUpdate(true)
     }, 30000);
    
    useEffect(() => {

        const get_data = async () => {

            const data = await (
                await fetch('/api/leaderboard?' + new URLSearchParams({
                    round_uuid: round_uuid,
                    secondary: props.secondary
                }), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.token
                    }
                })
            ).json();
            
            setData(data)
            setLoading(false);
            setUpdate(false)

        };

        get_data();

    }, [update]);

    if (isLoading) {
        return <>
            <div className='centering-loading'>
                < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
            </div>
        </>;
    }

    return (
        <>
            {/* Display your secondary scorecard if*/}
            {isStandardLeaderboard(data.game_format) || props.secondary ? (
                <LeaderboardPageStandard
                    token={props.token}
                    leaderboard={data.leaderboard}
                    gameFormat={data.game_format}
                    round_uuid={round_uuid}
                    data={data}
                    secondary={props.secondary}
                />
            ) : null}

            {isMatchPlay(data.game_format) && !props.secondary ? (
                <LeaderboardPageMatch
                    token={props.token}
                    round_uuid={round_uuid}
                    leaderboard={data.match_data}
                    data={data}
                />
            ) : null}

            {isBestBall(data.game_format) && !props.secondary ? (
                <LeaderboardTeamsPage
                    token={props.token}
                    round_uuid={round_uuid}
                    leaderboard={data.leaderboard}
                    data={data}
                />
            ) : null}


            {(isBestBallMatch(data.game_format)) && !props.secondary ? (
                <LeaderboardPageMatch
                    token={props.token}
                    round_uuid={props.round_uuid}
                    leaderboard={data.match_data}
                    data={data}
                />
            ) : null}


        </>
    )
}

