import React, { useState, useEffect } from 'react';
import './Modal.css'

export default function Tooltip(props) {

    const [expand, setExpand] = useState(false)

    return (
        <>
            <img src="/images/tooltip.png" onClick={(e) => {e.stopPropagation(); setExpand(true);}} style={{ height: '20px', marginLeft:'10px'}} ></img>

            {expand ?
                <>
                    <div className="overlay" onClick={(e) => { setExpand(false); e.stopPropagation(); }}>
                        <div className="modal-container-2" >
                          <p> {props.text} </p>
                        </div>
                    </div>
                </>
                : null}

        </>
    )


}


