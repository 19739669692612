import React from 'react';
import { useState, useEffect } from 'react';
import ScorecardCard from '../components/ScorecardCard'
import { isMatchPlay } from '../misc/checks';
import { GAMEMODE } from "../misc/enums"

import { useNavigate } from "react-router-dom"
import MoonLoader from "react-spinners/MoonLoader";
import useToken from '../components/useToken'

export const ScorecardPage = (props) => {
    const navigate = useNavigate();
    const { token } = useToken();

    const [isLoading, setLoading] = useState(true);
    const [strokes, setStrokes] = useState('')
    const [currentHole, setCurrentHole] = useState()

    const [players, setPlayers] = useState()
    const [game, setGame] = useState()

    const [update, setUpdate] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const [msg, setMsg] = useState("")

    useEffect(() => {

        const get_data = async () => {

            const data = await (
                await fetch('/api/scorecard', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
            ).json();

            if (data.msg === "No scorcard") {
                setMsg("Game not started yet / No scorecard")
                setLoading(false);
            }

            setPlayers(data.players)
            setGame(data.game)
            setCurrentHole(data.game.thru + 1)

            var array = []
            data.players.map((item) => {

                var json = {
                    'uuid': item.uuid,
                    'strokes': item.strokes
                };

                array.push(json)

            })
            setStrokes(array)
            setUpdate(false)
            setLoading(false);
        };
        get_data();

    }, [update]);

    if (isLoading) {
        return <>
            <div className='centering-loading'>
                < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
            </div>
        </>;
    }

    return (
        <>
            {msg === "" ?
                <>
                    <div className='score-container'>
                        {/* <Header title='Scorecard' gameformat={game.game_format} /> */}

                        <div className='scorecard-header-container'>

                            <div className="header-four">
                                {/* Index {scoreData.index} */}
                            </div>
                            <div className='header-three'>
                                {game.game_format === GAMEMODE.STABLEFORD ?
                                    <span className='header-item'> Score </span>
                                    :
                                    <span className='header-item'> {isMatchPlay(game.game_format) ? '' : 'To Par'} </span>
                                }
                            </div>

                            <div className='header-two'>
                                {game.thru === 0 ? '' : (
                                    <>
                                        <span className='header-item'> {game.thru} </span>
                                        <span className='header-item'> Par {game.prev_par}</span>
                                    </>
                                )}
                            </div>

                            <div className='header-one'>
                                <span className='header-item'> {game.thru + 1} </span>
                                <span className='header-item'> Par {game.par}</span>
                            </div>

                        </div>

                        {players.map((item, index) =>
                            <ScorecardCard
                                key={item.uuid}
                                data={item}
                                index={index}
                                strokes={item.strokes}
                                strokes_sub={item.strokes_sub}
                                addStrokes={(value) => {
                                    let newArr = [...strokes]
                                    newArr[value.index] = value;
                                    setStrokes(newArr)
                                }}
                                nextInput={(currentIndex) => {
                                    var next = currentIndex + 1
                                    var element = document.getElementById(next)
                                    if (element != null) {
                                        element.focus()
                                    }
                                }}

                                nextHole={() => {
                                    nextHole();
                                }}
                            />
                        )}

                        <input id='inpt-next' style={{ height: '0px', width: '0px', backgroundColor: 'rgba(0,0,0,0)', border: 'none' }} />

                        <div className='centering'>
                            <div className="scorecard-hole-input">

                                {game.thru === 0 ? <div style={{ width: '65px', height: '30px' }} /> :
                                    <img style={{ transform: 'rotate(180deg)' }} className="scorecard-chevron" src="/images/chevron.png" onClick={() => prevHole()}></img>
                                }

                                <div className='index-container' style={{ marginBottom: '10px' }}>
                                    Index {game.index}
                                </div>

                                <select style={{ height: '40px', width: '50px', textAlign: 'center' }}
                                    onChange={(e) => handleHoleChange(e.target.value)} value={currentHole}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                </select>

                                {game.thru === 18 ? '' :
                                    <img className="scorecard-chevron" src="/images/chevron.png" onClick={() => nextHole()} disabled={disableButton} ></img>
                                }

                            </div>
                        </div>

                    </div>

                    <div className="footer-switch" onClick={() => navigate("/leaderboard")}>
                        <img src="/images/icon_switch.png" alt="" style={{ marginRight: '15px' }} />
                        Leaderboard
                    </div>
                </>
                : <div className='centering-container' style={{fontSize: '16pt', color: 'white', fontWeight: '700'}}>
                    {msg}
                    </div>}
        </>
    )


    function handleHoleChange(hole) {

        var body = {
            'round_uuid': game.round_uuid,
            'player_uuid': players[0].uuid,
            'change_hole': parseInt(hole)
        };

        const set_data = async () => {

            const data = await (
                await fetch('/api/scorecard/change_hole', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            window.location.reload();

        };

        set_data();

    }

    function nextHole() {

        setDisableButton(true)
        setLoading(true)

        var body = {
            'player_data': strokes,
            'thru': currentHole
        };

        const set_data = async () => {

            const data = await (
                await fetch('/api/scorecard/next', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            if(data.msg == 'done'){
                navigate("/finished")
            }

            setUpdate(true)

        };
        set_data();
    }

    function prevHole() {
        setDisableButton(true)

        var body = {
            'thru': currentHole
        };

        const set_data = async () => {

            const data = await (
                await fetch('/api/scorecard/prev', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            window.location.reload();

        };

        set_data();

    }
}