import React from "react";
import { useNavigate } from "react-router-dom"


export const LogoutPage = (props) => {

    const navigate = useNavigate();

    return (

        <>

            {props.removeToken()}
            <>
                You've been logged out...
            </>

            {navigate("/")}
        </>

    )
}
