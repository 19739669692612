import React from 'react'
import Select from 'react-select';
import { useEffect, useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import useToken from './useToken'
import { SelectStyle, SelectStyleFull } from "../misc/styles";

export const SelectGolfClubCourseTee = (props) => {
    const { token } = useToken();
    const [isLoading, setLoading] = useState(true);
    const [bthLoad, setBtnLoad] = useState(false)
    const [data, setData] = useState(false)

    const [golfclubOptions, setGolfClubOptions] = useState([{ value: "", label: "No Options" }])
    const [golfclub, setGolfclub] = useState('')

    const [courseOptions, setCourseOptions] = useState([{ value: "", label: "No Options" }])
    const [course, setCourse] = useState('')

    const [teeOptions, setTeeOptions] = useState([{ value: "", label: "No Options" }])
    const [tee, setTee] = useState('')

    useEffect(() => {
        const get_data = async () => {
            const data = await (
                await fetch('/api/gc/init', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })

            ).json();

            const gc_array = [];
            const course_array = [];
            const tee_array = [];

            data.latest_played.map((gc) => {
                gc_array.push({ value: gc.uuid, label: gc.name });
                return;
            })

            data.course_options.map((gc) => {
                course_array.push({ value: gc.uuid, label: gc.name });
                return;
            })

            data.tee_options.map((gc) => {
                tee_array.push({ value: gc.uuid, label: gc.name });
                return;
            })
            setData(data)

            //assign the latest played courses
            setGolfClubOptions(gc_array)
            setCourseOptions(course_array)
            setTeeOptions(tee_array)

            setGolfclub(data.default_gc)
            setCourse(data.default_course)
            setTee(data.default_tee)

            if (!props.standalone) {
                props.setCourse(data.default_course)
                props.setTee(data.default_tee)
            }

            setLoading(false);
        };

        get_data();

    }, []);

    if (isLoading) {
        return <>
            <div className='centering-loading'>
                < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
            </div>
        </>
    }

    return (
        <>
            <SelectGolfClub
                standalone={props.standalone}
                value={golfclub}
                golfclubOptions={golfclubOptions}
                setCourseOptions={(options) => {
                    setCourseOptions(options);

                    if (options.length == 1) {
                        
                        setCourse(options[0]);
                        if(!props.standalone){
                            props.setCourse(options[0]);
                        }
                        //There are duplicates...
                        const get_data = async () => {
                            const data = await (
                                await fetch('/api/course/tees?' + new URLSearchParams({
                                    course_uuid: options[0].value,
                                }), {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token
                                    }
                                })
                
                            ).json();
                
                            const array = [];
                            data.map((tee) => {
                                array.push({ value: tee.uuid, label: tee.name });
                            })
                
                            setTeeOptions(array)
                
                        };
                
                        get_data();

                    } else {
                        setCourse({ value: "", label: "Select" })
                    }

                    setTee({ value: "", label: "Select" })
                }}
                setGolfClub={(golfclub) => {setGolfclub(golfclub)}}
            />

            <SelectCourse
                standalone={props.standalone}
                value={course}
                courseOptions={courseOptions}
                setCourse={(course) => {
                    setCourse(course);
                    if (!props.standalone) {
                        props.setCourse(course)
                    }
                }}
                setTeeOptions={(options) => {
                    setTeeOptions(options)
                }}
            />

            <SelectTee
                standalone={props.standalone}
                teeOptions={teeOptions}
                value={tee}
                setTee={(e) => {
                    setTee(e)
                    if (!props.standalone) {
                        props.setTee(e);
                    }
                }}
            />

            {props.standalone ?
                <button style={{ width: '100%', marginTop: '5px' }} onClick={() => changeCourse()} disabled={props.isDisabled}>
                    {bthLoad ?
                        <div className='centering-loading'>
                            < MoonLoader color="rgba(0, 0, 0, 1)" size={20} />
                        </div>
                        :
                        'Save'
                    }
                </button> : null
            }
        </>
    );

    function changeCourse() {
        setBtnLoad(true);

        if (course === "") {
            alert("Please select course")
            return
        }

        if (tee === "") {
            alert("Please select tee")
            return
        }

        var body = {
            "name": "",
            "round_uuid": data.round_uuid,
            "course_uuid": course.value,
            "tee_uuid": tee.value,
        };

        const set_data = async () => {
            const data = await (
                await fetch('/api/round/change_course', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json();

            props.setUpdate()
            setBtnLoad(false)

        };

        set_data();
    }

}

export const SelectGolfClub = (props) => {
    const { token } = useToken();

    const [golfclubOptions, setGolfClubOptions] = useState(props.golfclubOptions)

    return (
        <>
            <Select
                className="input-setup"
                placeholder="Golf Club"
                value={props.value}
                options={golfclubOptions}
                onInputChange={(e) => searchGolfClub(e)}
                onChange={(e) => handleGolfClubSelect(e)}
                styles={props.standalone ? SelectStyleFull : SelectStyle}

            />
        </>
    )

    function searchGolfClub(input) {

        const get_data = async () => {
            const data = await (
                await fetch('/api/gc/filtered?' + new URLSearchParams({
                    name: input
                }), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
            ).json();

            const array = [];

            data.map((gc) => {
                array.push({ value: gc.uuid, label: gc.name });
            })

            setGolfClubOptions(array);

        };

        //if we're inputing more then three chars, search for the gc's
        if (input.length > 2) {
            get_data();
        }

    }

    function handleGolfClubSelect(e) {

        props.setGolfClub(e)

        const get_data = async () => {

            const array = [];

            const data = await (
                await fetch('/api/gc/courses?' + new URLSearchParams({
                    gc_uuid: e.value
                }), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })

            ).json();

            data.map((course) => {
                array.push({ value: course.uuid, label: course.name });
            })

            props.setCourseOptions(array);

        };

        get_data();
    }
}

export const SelectCourse = (props) => {

    const { token } = useToken();

    return (
        <>
            {props.courseOptions.length > 1 ?
            <Select
                className="input-setup"
                value={props.value}
                options={props.courseOptions}
                onChange={(e) => getTeesAndSetCourse(e)}
                styles={props.standalone ? SelectStyleFull : SelectStyle}
                isSearchable={false}
            />
            : null
            }
        </>
    )

    function getTeesAndSetCourse(e) {

        const get_data = async () => {
            const data = await (
                await fetch('/api/course/tees?' + new URLSearchParams({
                    course_uuid: e.value
                    // player_uuid: props.player_uuid,
                    // gender: props.gender
                }), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })

            ).json();

            const array = [];
            data.map((tee) => {
                array.push({ value: tee.uuid, label: tee.name });
            })

            props.setCourse(e);
            props.setTeeOptions(array)

        };

        get_data();
    }
}

export const SelectTee = (props) => {
    return (
        <>
            <Select
                className="input-setup"
                options={props.teeOptions}
                value={props.value}
                onChange={(e) => { props.setTee(e) }}
                styles={props.standalone ? SelectStyleFull : SelectStyle}
                isSearchable={false}
            />
        </>
    )
}

export const SelectTeeStandalone = (props) => {
    const { token } = useToken();
    const [isLoading, setLoading] = useState(true);

    const [teeOptions, setTeeOptions] = useState([{ value: "", label: "No Options" }])
    const [tee, setTee] = useState(props.tee)

    useEffect(() => {

        const dataFetch = async () => {

            const array = [];

            const data = await (
                await fetch('/api/course/tees?' + new URLSearchParams({
                    course_uuid: props.course_uuid,
                    player_uuid: props.player_uuid,
                    gender: props.gender
                }), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })

            ).json();

            data.map((tee) => {
                array.push({ value: tee.uuid, label: tee.name });
            })

            setTeeOptions(array);        

        };

        dataFetch();
        setLoading(false);
 
    }, []);

    if (isLoading) {
        return <>
            <div className='centering-loading'>
                < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
            </div>
        </>
    }

    return (
        <>
            <SelectTee
                teeOptions={teeOptions}
                value={tee}
                setTee={(e) => {
                    setTee(e); props.setTee(e);
                }}
            />
        </>
    )
}
