import React from "react";
import { useEffect, useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { Helmet } from "react-helmet";

export const AvailableCoursesPage = (props) => {

    const [isLoading, setLoading] = useState(true);
    const [golfclubs, setGolfClubs] = useState(true);

    useEffect(() => {
        const dataFetch = async () => {
            const data = await (
                await fetch('/api/gc/all', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '
                    }
                })

            ).json();

            setGolfClubs(data);
            setLoading(false);

        };
        dataFetch();
    }, []);

    if (isLoading) {
        return <>
            <div className='centering-loading'>
                < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
            </div>
        </>;
    }


    return (
        <>
            <Helmet>
                <title>Golfingbombs - Free Golf Score App | Available Courses </title>
                <meta charSet="utf-8" name="description" content="Discover available golf courses with Golfingbombs, your free golf score app for tournaments and events." />
                <link rel="canonical" href="https://www.golfingbombs.com/availablecourses" />
            </Helmet>

            <div className="centering-container">
                <div className="gb-background" style={{}}>
                    <h1> Available Courses </h1>
                    <>
                        {
                            golfclubs.map((item, index) =>
                                <div style={{ width: '100%' }}>
                                    {item.name}
                                </div>
                            )
                        }
                    </>
                </div>
            </div>
        </>
    )

}