import React from 'react';
import { useState } from "react";
import './Friend.css'

const Friend = (props) => {

    const [isSelected, setIsSelected] = useState(false)

    function handleOnClick(e) {

        if (isSelected) {
            setIsSelected(false);
            props.delFromList(e.id)
        } else {
            setIsSelected(true)
            props.addToList(e.id)
        }
    }

    function handleOnClickX(e) {
        e.stopPropagation()

        if (window.confirm("Do you really want to remove " + props.name + " as a friend?")) {

            var body = {
                "player_uuid": props.uuid
            };

            const set_data = async () => {
                const data = await (
                    await fetch('/api/remove_friend', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + props.token
                        },
                        body: JSON.stringify(body)
                    })
                ).json()

                props.refreshList()

            };
            set_data();
        }
    }

    return (
        <div className='friend' id={props.uuid} onClick={props.addX ? null :
            (e) => handleOnClick(e.currentTarget)}
            style={{ backgroundColor: isSelected ? '#ffb500' : '' }}
            >
            <div className='friend-c1'>
                <span className='friend-name'> {props.name} </span>
                <span className='friend-email'> {props.email}</span>
            </div>

            <div className='friend-c2'>
                <span className='friend-hcp'> {props.hcp} </span>
            </div>

            {props.addX ? (
                <span className='friend-x' onClick={(e) => handleOnClickX(e)}> x </span>
            ) : null}

        </div>
    );
}


export default Friend;

