import React from "react";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom"
import './RegisterPage.css';
import { SelectStyleFull } from "../misc/styles";
import Select from 'react-select';
import MoonLoader from "react-spinners/MoonLoader";
import { Helmet } from "react-helmet";

export const RegisterPage = (props) => {

  const navigate = useNavigate();
  const nicknameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const password2ndRef = useRef()
  const hcpRef = useRef()
  const genderRef = useRef()
  const newsRef = useRef()

  const [msg, setMsg] = useState('')
  const [disableButton, setDisableButton] = useState(false)

  const handleSubmit = (event) => {
    setDisableButton(true)
    event.preventDefault();

    var gender = ""

    if (genderRef.current.getValue().length === 0) {
      setMsg("Select gender")
      setDisableButton(false)
      return
    } else {
      gender = genderRef.current.getValue()[0].value
    }

    var body = {
      "nickname": nicknameRef.current.value,
      "email": emailRef.current.value,
      "password": passwordRef.current.value,
      "password_2nd": password2ndRef.current.value,
      "hcp": hcpRef.current.value,
      "gender": gender,
      "newsletter": newsRef.current.checked
    };

    const set_data = async () => {

      const data = await (
        await fetch('/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          },
          body: JSON.stringify(body)
        })
      ).json()

      //If an access token is returned, then lets login and move to setup
      if (data.access_token !== undefined) {
        props.setToken(data.access_token)
        navigate("/profile");
      } else {
        setMsg(data.msg)
        setDisableButton(false)
      }

    };
    set_data();
  }


  return (
    <>

      <Helmet>
        <meta charSet="utf-8" name="description" content="Register for Golfingbombs membership to access exclusive features and content." />
        <title>Register for Golfingbombs membership</title>
        <link rel="canonical" href="https://www.golfingbombs.com/register" />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Golfingbombs Registration Page",
        "description": "Register for Golfingbombs membership to access exclusive features and content.",
        "url": "https://www.golfingbombs.com/register"
      }
    `}
        </script>
      </Helmet>

      <div className="centering-container">
        <div className="gb-background" style={{ maxWidth: '300px' }}>
          <h1>Register</h1>

          <form onSubmit={handleSubmit} >
            <div className="form-ctrl">
              <label>Nickname</label>
              <input type='text' placeholder=""
                style={{ width: '100%' }}
                ref={nicknameRef}
              ></input>
            </div>

            <div className="form-ctrl">
              <label>Email</label>
              <input type='text' placeholder=""
                style={{ width: '100%' }}
                ref={emailRef}
              ></input>
            </div>

            <div className="form-ctrl">
              <label>Gender</label>
              <Select
                className="input-setup"
                options={[{ value: 1, label: "Male" }, { value: 0, label: "Female" }]}
                ref={genderRef}
                styles={SelectStyleFull}
                isSearchable={false}
              />
            </div>

            <div className="form-ctrl">
              <label>Password</label>
              <input type='password' placeholder=""
                style={{ width: '100%' }}
                ref={passwordRef}
              ></input>

              <input type='password'
                style={{ marginTop: '5px', width: '100%' }}
                placeholder="Confirm password"
                ref={password2ndRef}
              ></input>
            </div>

            <div className="form-ctrl">
              <label>HCP</label>
              <input type='text' placeholder=""
                style={{ width: '100%' }}
                ref={hcpRef}
              ></input>
            </div>

            <div className="form-ctrl" style={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" style={{ width: 'auto' }}
                ref={newsRef}
              />
              <span style={{ marginLeft: '10px' }}> Signup for newsletter </span>
            </div>

            <button style={{ width: '100%', marginTop: '15px' }} disabled={disableButton}>
              {disableButton ?
                <div className='centering-loading'>
                  < MoonLoader color="rgba(0, 0, 0, 1)" size={20} />
                </div>
                :
                'Register'
              }
            </button>

            {msg !== '' ?
              <div className="msg" style={{ fontSize: '12pt' }}>{msg}</div>
              : null}
          </form>

        </div>
      </div>
    </>
  )
}