import React, { Component } from 'react';
import { useEffect, useState } from "react";
import Friend from './Friend'
import './Friend.css'
import { useRef } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import useToken from '../components/useToken'

const Friendslist = (props) => {
    const {token} = useToken();
    const [friends, setFriends] = useState('')
    const [isLoading, setLoading] = useState(true);
    const [update, setUpdate] = useState(false);

    const friendRef = useRef()

    useEffect(() => {
        const get_data = async () => {

            const data = await (
                await fetch('/api/friends', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
            ).json();

            setFriends(data)
            setUpdate(false)
            setLoading(false);

        };
        get_data();
    }, [update]);


    if (isLoading) {
        return <>
            <div className='centering-loading'>
                < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
            </div>
        </>;
    }

    function handleAddFriend() {

        var body = {
            'email': friendRef.current.value
        };

        const set_data = async () => {
            const data = await (
                await fetch('/api/add_friend', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            if (data.msg === 'Success') {
                friendRef.current.value = '';
            }

        };
        set_data();

    }

    function addFriend(e) {

        var body = {
            "uuid": e
        };

        const set_data = async () => {
            const data = await (
                await fetch('/api/lobby/add_friend', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            props.setUpdate()

        };
        set_data();
    }


    function removeFriend(e) {

        var body = {
            "uuid": e
        };

        const set_data = async () => {
            const data = await (
                await fetch('/api/lobby/remove_player', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            props.setUpdate()

        };
        set_data();
    }



    return (
        <>
            <div className="form-ctrl" style={{ display: props.addInput ? '' : 'none' }}>
                <label>Add to friends list </label>
                <input type='text'
                    placeholder="@"
                    ref={friendRef}
                ></input> 
            </div>

            <input type='submit' style={{width:'100px', display: props.addInput ? '' : 'none', marginTop:'15px'}} value='Add'
                    onClick={() => { handleAddFriend(); setUpdate(true); }}
                ></input>

            <div style={{width:'100%', marginTop:'20px'}}>
            {friends.length === 0 ? 'No friends to display' : ''}

            </div>

            {friends.map((item, index) =>
                <Friend token={token}
                    uuid={item.uuid}
                    key={index}
                    name={item.nickname}
                    hcp={item.hcp}
                    email={item.email}
                    addX={props.addX}
                    addButton={true}
                    
                    addToList={(e) => {
                        addFriend(e)
                    }}
                    
                    delFromList={(e) => {
                        removeFriend(e)
                    }}
                  
                    refreshList={() => { setUpdate(true) }}
                />
            )}
        </>
    );
}


export default Friendslist;
