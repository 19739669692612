import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom"
import MoonLoader from "react-spinners/MoonLoader";
import { Helmet } from "react-helmet";

export const LoginPage = (props) => {

  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [remember, setRemember] = useState();
  const [disableButton, setDisableButton] = useState(false)

  const [msg, setMsg] = useState('')

  const handleSubmit = (event) => {
    setMsg("");
    event.preventDefault();
    setDisableButton(true)

    var body = {
      "email": email,
      "password": password,
      "remember": remember
    };

    const set_data = async () => {

      const data = await (
        await fetch('/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          },
          body: JSON.stringify(body)
        })
      ).json()

      setMsg(data.msg)
      setDisableButton(false)

      //If an access token is returned, then lets login and move to setup
      if (data.access_token !== undefined) {
        props.setToken(data.access_token)
        props.setUpdate()
        navigate("/profile");
      }

    };

    set_data();

  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" name="description" content="Login to Golfingbombs to manage your golf tournaments. Access your account, reset your password, and enjoy seamless golf scorekeeping." />
        <title>Login to Golfingbombs - Manage Your Golf Tournaments</title>
        <link href="https://www.golfingbombs.com/login" />
      </Helmet>

      <div className="centering-container">
        <div className="gb-background" style={{ maxWidth: '300px' }}>
          <h1>Login</h1>
          {/* <h2 >Manage Your Golf Tournaments with Ease</h2> */}
          <form onSubmit={handleSubmit} method="post">
            <div className="form-ctrl">
              <label>Email</label>
              <input type='username' placeholder=""
                style={{ width: '100%' }}
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>

            <p onClick={() => navigate("/resetpassword")} style={{ cursor: 'pointer', fontSize: '10pt' }}>
              Forgot password
            </p>


            <div className="form-ctrl">
              <label>Password</label>
              <input type='password' placeholder=""
                style={{ width: '100%' }}
                value={password || ''}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>

            <button style={{ width: '100%', marginTop: '15px' }} disabled={disableButton}>
              {disableButton ?
                <div className='centering-loading'>
                  < MoonLoader color="rgba(0, 0, 0, 1)" size={20} />
                </div>
                :
                'Login'
              }
            </button>

            {msg !== '' ?
              <p className="msg" style={{ fontSize: '12pt' }}>{msg}</p>
              : null}

          </form>
        </div>
      </div>
    </>
  )
}
