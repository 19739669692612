import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import useToken from './components/useToken'
import React, { useState, useEffect } from 'react';

import { LandingPage } from './pages/LandingPage'
import { LoginPage } from './pages/LoginPage'
import { LogoutPage } from './pages/LogoutPage'
import { RegisterPage } from './pages/RegisterPage'
import { AboutPage } from './pages/AboutPage'
import { SettingsPage } from './pages/SettingsPage'
import { SetupPage } from './pages/SetupPage';
import { ScorecardPage } from './pages/ScorecardPage';
import { LeaderboardPage } from './pages/LeaderboardPage';
import { LeaderboardPage2nd } from './pages/LeaderboardPage2nd';
import { LobbyPage } from "./pages/LobbyPage";
import { CoursePage } from "./pages/CoursePage";
import { FinishedRoundPage } from './pages/FinishedRoundPage';
import { JoinPage } from './pages/JoinPage';
import { OpenplayPage } from './pages/OpenPlay';
import { GetStartedPage } from './pages/GetStartedPage';
import MoonLoader from "react-spinners/MoonLoader";
import { EventPage } from './pages/EventPage';
import './App.css';
import { RoundsPage } from './pages/RoundsPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { AvailableCoursesPage } from './pages/AvailableCourses';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';
import { TermsOfServicePage } from './pages/TermsofservicePage';

function App() {

  const { token, removeToken, setToken, isAuth } = useToken();
  const [round_uuid, setRound_uuid] = useState('')
  const [isInLobby, setIsInLobby] = useState(false)
  const [isLoading, setLoading] = useState(true);
  const [round_gameformat, setRound_gameformat] = useState()
  const [update, setUpdate] = useState(false)
  const [newEvent, setNewEvent] = useState(false)

  useEffect(() => {

    if (token == undefined) {
      setLoading(false);
    } else {

      fetch('/api/user_info', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
        .then((response) => {

          if (token != undefined && response.status == 401) {
            removeToken()
            window.location.reload();
          }

          return response.json()

        })
        .then((json) => {

          if (json.msg == 'Token has expired' || json.msg == 'Signature verification failed') {
            removeToken()
            window.location.reload();
          }

          if (json.setup_round_uuid !== undefined) {
            setRound_gameformat(json.gameformat)
            setRound_uuid(json.active_round_uuid)
            setIsInLobby(true)
          } else {
            setIsInLobby(false)
            setRound_uuid("")
          }

          setNewEvent(json.event_new)
          setUpdate(false)
          setLoading(false)

        });
    }

  }, [update]);

  if (isLoading) {
    return <>
      <div className='centering-loading'>
        < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
      </div>
    </>;
  }

  return (

    <BrowserRouter>
      <div className="App" >

        <Navbar collapseOnSelect expand="lg" bg='dark' variant="dark" className="nav-nomnom"  >

          <Navbar.Brand href="/" >
            <img className="logo" style={{ marginLeft: '10px' }} src="/images/icon.png" alt="" />
            <span className='logo-title' style={{ marginLeft: "0px", fontSize: '20pt', verticalAlign: 'middle', fontWeight: '700' }}> GOLFINGBOMBS </span>
          </Navbar.Brand>

          <Navbar.Toggle style={{ marginRight: '20px' }} aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">

              {isAuth(token) ?
                (
                  <>
                    <Nav.Link as={Link} to="/login" href='/login' >Login</Nav.Link>
                    <Nav.Link as={Link} to="/register" href='/register' >Register</Nav.Link>
                    <Nav.Link as={Link} to="/getstarted" href='/getstarted' >Beginners guide</Nav.Link>
                    {/* <Nav.Link as={Link} to="/availablecourses" href='/availablecourses' >Available courses</Nav.Link> */}
                    <Nav.Link as={Link} to="/about" href='/about' > About Us </Nav.Link>

                  </>
                ) : (
                  <>
                    <Nav.Link as={Link} to="/event" href='/' style={{ zIndex: '999', marginLeft: 'auto', marginRight: '5px' }}>
                      {newEvent ?
                        <img src="/images/icon_bell_new.png" style={{ height: '20px' }} />
                        :
                        <img src="/images/icon_bell.png" style={{ height: '20px' }} />
                      }
                    </Nav.Link>

                    <Nav.Link as={Link} to="/about" href='/about' >About us</Nav.Link>
                    <NavDropdown title="Game" id="collasible-nav-dropdown" >
                      <NavDropdown.Item href="/getstarted">Beginners Guide</NavDropdown.Item>
                      <NavDropdown.Item href="/newround">New Round</NavDropdown.Item>
                      {isInLobby ? (
                        <NavDropdown.Item href="/editround">Edit Round</NavDropdown.Item>
                      ) : null}

                      {round_uuid !== '' ? (
                        <>
                          <NavDropdown.Item href="/scorecard">Scorecard</NavDropdown.Item>
                          <NavDropdown.Item href="/leaderboard">Leaderboard</NavDropdown.Item>
                        </>
                      ) : null}

                    </NavDropdown>
                    <Nav.Link as={Link} to="/rounds" href='/rounds' >History</Nav.Link>
                    <Nav.Link as={Link} to="/profile" href='/profile' >Profile</Nav.Link>

                  </>
                )}
            </Nav>
          </Navbar.Collapse>

        </Navbar>

        <div>
          <Routes>
            <Route path="/" exact element={<LandingPage />} />
            <Route path="/login" element={<LoginPage setToken={setToken} setUpdate={(e) => setUpdate(e)} />} />
            <Route path="/logout" element={<LogoutPage removeToken={removeToken} />} />
            <Route path="/register" element={<RegisterPage setToken={setToken} />} />
            <Route path="/getstarted" element={<GetStartedPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/availablecourses" element={<AvailableCoursesPage />} />

            <Route path="/event" element={<EventPage token={token} setUpdate={() => setUpdate(true)} />} />
            <Route path="/rounds" element={<RoundsPage token={token} setUpdate={() => setUpdate(true)} />} />
            <Route path="/newround" element={<SetupPage setUpdate={() => setUpdate(true)} />} />
            <Route path="/editround" element={<LobbyPage setRound={(e) => { setRound_uuid(e) }} setUpdate={() => setUpdate(true)} />} />


            <Route path="/scorecard" element={<ScorecardPage setUpdate={() => setUpdate(true)} />} />
            <Route path="/leaderboard" element={<LeaderboardPage token={token} round_uuid={round_uuid} />} />
            <Route path="/leaderboard_2nd" element={<LeaderboardPage2nd token={token} round_uuid={round_uuid} secondary={true} />} />
            <Route path="/profile" element={<SettingsPage token={token} setUpdate={() => setUpdate(true)} />} />
            <Route path="/course" element={<CoursePage token={token} />} />
            <Route path="/finished" element={<FinishedRoundPage token={token} setUpdate={() => { setUpdate(true); setIsInLobby(false) }} />} />
            <Route path="/lobby/join" element={<JoinPage token={token} setUpdate={() => setUpdate(true)} setToken={setToken} />} />
            <Route path="/lobby/openplay" element={<OpenplayPage token={token} setUpdate={() => setUpdate(true)} setToken={setToken} />} />

            <Route path="/resetpassword" element={<ResetPasswordPage />} />
            <Route path="/tos" element={<TermsOfServicePage />} />
            <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />

          </Routes>

        </div>

      </div>
    </BrowserRouter>
  );
}


export default App;

