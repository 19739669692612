import React from 'react'
import { useState, useEffect } from 'react';
import PreviousRoundCard from './PreviousRoundCard';
import MoonLoader from "react-spinners/MoonLoader";

const PreviousRoundList = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState()
  const [update, setUpdate] = useState()


  useEffect(() => {

    const get_data = async () => {

      const data = await (
        await fetch('/api/previously_played', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          }
        })
      ).json();

      setData(data)
      setLoading(false);

    };
    get_data();

  }, [update]);

  if (isLoading) {
    return <>
      <div className='centering-loading'>
        < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
      </div>
    </>;
  }

  return (
    <>

      {data.length === 0 ?
        <div style={{ padding: '30px', color: 'white' }}>
          No rounds played
        </div>
        :
        <>
              {data.map((item, index) =>
                <PreviousRoundCard
                  key={index}
                  token={props.token}
                  round_name={item.round_name}
                  game_format={item.game_format}
                  uuid={item.round_uuid}
                  points={item.round_points}
                  strokes={item.round_strokes}
                  to_par={item.round_to_par}
                  score_mp={item.round_score_mp}
                  setUpdate={() => setUpdate(true)}
                  updateLobby={() => props.setUpdate()}
                  
                />
              )}
          
        </>
      }

    </>
  )



}

export default PreviousRoundList
