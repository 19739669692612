import React from "react";
import { Helmet } from "react-helmet";


export const PrivacyPolicyPage = (props) => {

    return (
        <>
         <Helmet>
                <title>Golfingbombs - Privacy Policy</title>
                <meta charSet="utf-8" name="description" content="The Privacy Policy of Golfingbombs, governing data collection, usage, and disclosure on the website." />
                <link rel="canonical" href="https://www.golfingbombs.com/privacypolicy" />
            </Helmet>

            <div className="centering-container">
                <div className="gb-background" style={{ width: '95%' }}>
                    <p>
                        <h1> Privacy Policy</h1>
                        This Privacy Policy governs the manner in which Golfingbombs collects, uses, maintains, and discloses information collected from users ("Users") of the [Your Website URL] website ("Site"). This privacy policy applies to the Site and all products and services offered by Golfingbombs.
                    </p>
                    <p>
                        <h5>1. Personal Identification Information</h5>
                        We may collect personal identification information from Users in various ways, including, but not limited to, when Users visit our site, register on the site, fill out a form, respond to a survey, subscribe to the newsletter, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain Site-related activities.
                    </p>
                    <p>
                        <h5>2. Non-personal Identification Information</h5>
                        We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer, and technical information about Users' means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
                    </p>
                    <p>
                        <h5>3. Web Browser Cookies</h5>
                        Our Site may use "cookies" to enhance User experience. Users' web browsers place cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
                    </p>
                    <p>
                        <h5>4. How We Use Collected Information</h5>
                        Golfingbombs may collect and use Users' personal information for the following purposes:
                        <ul>
                            <li>To personalize user experience</li>
                            <li>To improve our Site</li>
                            <li> To send periodic emails</li>
                        </ul>
                    </p>
                    <p>
                        <h5>5. How We Protect Your Information</h5>
                        We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of Users' personal information, username, password, transaction information, and data stored on our Site.
                    </p>
                    <p>
                        <h5>6. Sharing Your Personal Information</h5>
                        We do not sell, trade, or rent Users' personal identification information to others.
                    </p>
                    <p>
                        <h5>7. Changes to This Privacy Policy</h5>
                        Golfingbombs has the discretion to update this privacy policy at any time. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
                    </p>
                    <p>
                        <h5>8. Your Acceptance of These Terms</h5>
                        By using this Site, you signify your acceptance of this policy.
                    </p>
                </div>
            </div>
        </>
    )

}