import React from 'react'
import { useState } from 'react';
import LeaderboardCardMatch from '../components/LeaderboardCardMatch';
import { useNavigate } from "react-router-dom"
import { GAMEMODE } from "../misc/enums"

export const LeaderboardPageMatch = (props) => {

    const navigate = useNavigate();
    const [link, setLink] = useState('');

    const redStyle = {
        backgroundColor: '#df2032',
    }

    const blueStyle = {
        backgroundColor: '#3155ce',
        // backgroundColor: 'rgba(0, 0, 255, 0.5)'
    }

    const greyStyle = {
        backgroundColor: '#e0e0e0',
    }

    function styleTeamScore(color) {
        
        if(color === 'grey'){
            return greyStyle
        }else if(color === 'blue'){
            return blueStyle
        }else{
            return redStyle
        }
    }

    function presentTeamScore(score) {
        var dt = JSON.parse(score)
        var color = ''
        var aTemp = ''

        if (dt[0] === dt[1]) {
            aTemp = 'A/S'
            color = 'grey'
        } else if (dt[0] > dt[1]) {
            aTemp = dt[0] - dt[1]
            aTemp = aTemp + 'UP'
            color = 'red'
        } else {
            aTemp = dt[1] - dt[0]
            aTemp = aTemp + 'UP'
            color = 'blue'
        }

        const text = 
        <div className="lb-score-container" style={{display: props.data.game_format === GAMEMODE.MATCHPLAYTEAMS ? '' : 'none'}}>
            <div style={styleTeamScore(color)} className='lb-score'> {aTemp} </div>
        </div>

        return text
    }
    
    return (
        <>

            {presentTeamScore(props.data.prel_score)}

            {props.leaderboard.map((item, index) =>
                <LeaderboardCardMatch 
                key={index} 
                data={item} 
                game_format={props.data.game_format} 
                round_uuid={props.round_uuid}
                />
            )}

            <div style={{marginBottom: '50px'}}>
                
                {link === '' ? <img className="button-share" src="/images/icon_share.png" onClick={() => shareLink()}></img>
                    : <div style={{ backgroundColor: 'white', padding: '5px', textAlign: 'center', marginRight: '20px', borderRadius: '0px 5px 5px 0px' }}> {link} </div>}
                
                <img className="button-share" alt='' src="/images/icon_2nd.png" onClick={() => { navigate("/leaderboard_2nd?round_uuid=" + props.round_uuid) }}></img>

            </div>

            {props.round_uuid ?

                <div className="footer-switch" onClick={() => navigate("/scorecard")} >
                    <img src="/images/icon_switch.png" alt="" style={{ marginRight: '15px' }} />
                    Scorecard
                </div>
                : null
            }

        </>
    )

    function shareLink(){

        const get_data = async () => {
            const data = await (
                await fetch('/api/leaderboard_link', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.token
                    }
                })
            ).json();

               setLink(data.link)

            const el = document.createElement('textarea');
            el.value = data.link;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            alert(data.link + ' copied to clipboard!')

        };
    
        get_data();
    }





}

