import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arraySwap, SortableContext, rectSwappingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from '../components/SortableItem';
import './LobbyPage.css';
import Modal from '../components/Modal'
import { ERRORCODES, ROUND_STATE } from '../misc/enums'
import { useNavigate } from "react-router-dom"
import MoonLoader from "react-spinners/MoonLoader";
import { SelectStyleFull } from "../misc/styles";
import Select from 'react-select';
import useToken from '../components/useToken'
import { isBestBall, isSkins, isStableford, isStrokePlay } from '../misc/checks'
import { gamemodes, getGameformatName } from '../misc/enums';
import Tooltip from '../components/Tooltip';
import { TOOLTIP } from '../misc/utils';
import excelTemplate from '../assets/GolfingbombsTemplate.xlsx'
import { SelectGolfClubCourseTee } from '../components/SelectCourseTee';


export const LobbyPage = (props) => {
    const navigate = useNavigate();
    const { token } = useToken();
    const [game, setGame] = useState();
    const [player, setPlayer] = useState();

    const [openModal, setOpenModal] = useState(false)
    const [optionsVisable, setOptionsVisable] = useState(false)
    const [lobbyState, setLobbyState] = useState()
    const [round_uuid, setRoundUuid] = useState()
    const [round_name, setRoundName] = useState()
    const [useHCP, setUseHCP] = useState('')
    const [skinscarry, setSkinsCarry] = useState('')
    const [roundEvent, setRoundEvent] = useState('')
    const [showWinner, setShowWinner] = useState('')
    const [ppg, setPPG] = useState('')
    const [gamemode, setGamemode] = useState()
    const [qrCode, setQrCode] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [update, setUpdate] = useState(false)
    const [disabledStartButton, setDisabledStartButton] = useState(false)
    const [selectedFile, setSelectedFile] = useState()
    const [disableButtonTournament, setDisabledButtonTournament] = useState(false)
    const [msg, setMsg] = useState("")

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);

    };

    const handleSubmission = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        if (selectedFile === undefined) {
            alert("No file selected.")
            return
        }

        if (window.confirm("Are you sure you would like to upload? This will start setup of next tournament round.")) {
        } else {
            return;
        }

        const set_data = async () => {
            const data = await (
                await fetch('/api/lobby/upload', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    body: formData
                })
            ).json()

            if (data.msg !== 'Success') {
                setMsg(data.msg)
            } else {
                window.location.reload();
            }

        };

        set_data();

    };

    //TODO, if we're locking the round, wipe the sensors...
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(TouchSensor)
    );

    const sensorsOff = useSensors(
    );

    useEffect(() => {
        const dataFetch = async () => {

            const data = await (
                await fetch('/api/lobby', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
            ).json();

            setGame(data.game)
            setPlayer(data.player)
            setRoundName(data.game.round_name)
            setLobbyState(data.game.round_state)
            setUseHCP(data.game.use_hcp)
            setShowWinner(data.game.show_winner)
            setRoundUuid(data.game.round_uuid)
            setRoundEvent(data.game.round_event)
            setSkinsCarry(data.game.skinscarry)
            setPPG({ value: data.game.ppg, label: data.game.ppg.toString() })

            //page updates
            setUpdate(false)
            setLoading(false);

        };

        dataFetch();

    }, [update]);

    if (isLoading) {
        return <>
            <div className='centering-loading'>
                < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
            </div>
        </>;
    }

    return (
        <>

            <div className='options-container'>

                <div className='options'>

                    <div style={{ width: '100%', height: 'auto' }}>

                        <div className="form-ctrl" style={{}}>
                            <div style={{ fontSize: '14pt' }}> Use HCP
                                <Tooltip text={TOOLTIP.HANDICAP} />
                            </div>
                            <Select
                                className="input-setup"
                                defaultValue={useHCP ? { value: 1, label: "Yes" } : { value: 0, label: "No" }}
                                options={[{ value: 1, label: "Yes" }, { value: 0, label: "No" }]}
                                styles={SelectStyleFull}
                                isSearchable={false}
                                onChange={(e) => { use_hcp(e.value) }}
                                isDisabled={lobbyState === ROUND_STATE.INLOBBY ? false : true}
                            />
                        </div>

                        <button onClick={(e) => { setOpenModal(true); setQrCode(true); }} style={{ width: '100%', marginTop: '10px' }}>
                                Show QR-Code <Tooltip text={TOOLTIP.QR_CODE} />
                            </button>

                        {isBestBall(game.game_format) ?
                            <>
                                <div className="form-ctrl">
                                    <div style={{ fontSize: '14pt' }}> Number of scores </div>

                                    <Select
                                        className="input-setup"
                                        defaultValue={{ value: 2, label: "2" }}
                                        options={[{ value: 1, label: "1" }, { value: 2, label: "2" }, { value: 3, label: "3" }, { value: 4, label: "4" }]}
                                        styles={SelectStyleFull}
                                        isSearchable={false}
                                        onChange={(e) => { handleScoreCount(e.value) }}
                                        isDisabled={lobbyState === ROUND_STATE.INLOBBY ? false : true}
                                    />
                                </div>

                                <div className="form-ctrl">
                                    <div style={{ fontSize: '14pt' }}> Bestball Format </div>

                                    <Select
                                        className="input-setup"
                                        defaultValue={{ value: 1, label: "Strokeplay" }}
                                        options={[{ value: 1, label: "Strokeplay" }, { value: 2, label: "Stableford" }]}
                                        styles={SelectStyleFull}
                                        isSearchable={false}
                                        onChange={(e) => { }}
                                        isDisabled={lobbyState === ROUND_STATE.INLOBBY ? false : true}
                                    />
                                </div>

                            </>
                            : null}

                        {isSkins(game.game_format) ?
                            <>
                                <div className="form-ctrl">
                                    {/* <label style={{ fontWeight: '400' }}> Skins: Points carry </label> */}

                                    <div style={{ fontSize: '14pt' }}> Skins: Points carry
                                        <Tooltip text={TOOLTIP.SKINS_CARRY} />
                                    </div>

                                    <Select
                                        className="input-setup"
                                        defaultValue={skinscarry ? { value: 1, label: "Yes" } : { value: 0, label: "No" }}
                                        options={[{ value: 1, label: "Yes" }, { value: 0, label: "No" }]}
                                        styles={SelectStyleFull}
                                        isSearchable={false}
                                        onChange={(e) => { set_skinscarry(e.value) }}
                                        isDisabled={lobbyState === ROUND_STATE.INLOBBY ? false : true}
                                    />
                                </div>
                            </>
                            : null}

                    </div>

                    <button style={{ marginTop: '5px', width: '100%' }}
                        onClick={() => { optionsVisable ? setOptionsVisable(false) : setOptionsVisable(true) }}
                    >  Settings {optionsVisable ? '▲' : '▼'} </button>

                    {optionsVisable ?

                        <>
                            <div className="form-ctrl" style={{ marginTop: '0px' }}>
                                <label style={{ fontWeight: '400' }}> Round Name </label>
                                <input type='text' placeholder=""
                                    style={{ width: '100%' }}
                                    value={round_name || ''}
                                    onChange={(e) => { setRoundName(e.target.value) }}
                                    disabled={lobbyState === ROUND_STATE.INLOBBY ? false : true}
                                ></input>
                            </div>
                            <button style={{ width: '100%', marginTop: '5px' }} onClick={(e) => changeRoundName()}> Save </button>

                            <div style={{ width: '100%', height: 'auto', paddingRight: '5px' }}>

                                <div className="form-ctrl" style={{ marginTop: '0px' }}>
                                    <label style={{ fontWeight: '400' }}> Gamemode </label>
                                    <Select
                                        className="input-setup"
                                        defaultValue={{ value: game.game_format, label: getGameformatName(game.game_format) }}
                                        options={gamemodes}
                                        value={gamemode}
                                        onChange={(e) => { changeGamemode(e) }}
                                        isSearchable={false}
                                        isDisabled={lobbyState === ROUND_STATE.INLOBBY ? false : true}
                                    />
                                </div>

                                <div className="form-ctrl" style={{ marginTop: '0px' }}>
                                    <label style={{ fontWeight: '400' }}> Course </label>
                                </div>

                                <SelectGolfClubCourseTee standalone={true} setUpdate={() => setUpdate(true)} />
                            </div>

                            {isStrokePlay(game.game_format) || isStableford(game.game_format) ?
                                <div className="form-ctrl" style={{width: '100%'}}>
                                    <div style={{ fontSize: '14pt' }}> Show winner
                                        <Tooltip text={TOOLTIP.SHOW_WINNER} />
                                    </div>
                                    <Select
                                        className="input-setup"
                                        defaultValue={showWinner ? { value: 1, label: "Yes" } : { value: 0, label: "No" }}
                                        options={[{ value: 1, label: "Yes" }, { value: 0, label: "No" }]}
                                        onChange={(e) => { show_winner(e.value) }}
                                        isSearchable={false}
                                        isDisabled={lobbyState === ROUND_STATE.INLOBBY ? false : true}
                                    />
                                </div>
                                : null
                            }

                            <div style={{ width: '100%', height: 'auto' }}>
                                <div className="form-ctrl">
                                    <div style={{ fontSize: '14pt' }}> Event
                                        <Tooltip text={TOOLTIP.ROUNDEVENT} />
                                    </div>
                                    <Select
                                        className="input-setup"
                                        defaultValue={roundEvent === 1 ? { value: 1, label: "Standard" } : { value: 2, label: "Open Play" }}
                                        options={[{ value: 1, label: "Standard" }, { value: 2, label: "Open Play" }]}
                                        styles={SelectStyleFull}
                                        isSearchable={false}
                                        isDisabled={lobbyState === ROUND_STATE.INLOBBY ? false : true}
                                        onChange={(e) => { set_roundEvent(e.value) }}
                                    />
                                </div>
                            </div>

                            <div style={{ width: '100%', display: 'flex', padding: '0px' }}>
                                <div className="virtbutton" style={{ fontSize: '14pt', width: '100%', marginLeft: '0px', marginRight: '5px' }}> Players per group
                                    <Tooltip text={TOOLTIP.TOURNAMENT} />
                                </div>
                                <Select
                                    className="input-setup"
                                    defaultValue={ppg}
                                    options={[{ value: 1, label: "1" }, { value: 2, label: "2" }, { value: 3, label: "3" }, { value: 4, label: "4" }]}
                                    styles={SelectStyleFull}
                                    isSearchable={false}
                                    isDisabled={lobbyState === ROUND_STATE.INLOBBY ? false : true}
                                    onChange={(e) => { set_players_per_ball(e.value) }}
                                />

                            </div>

                            <div style={{ fontSize: '14pt' }}> General
                                <Tooltip text={TOOLTIP.ROUNDEVENT} />
                            </div>

                            <button disabled={lobbyState === ROUND_STATE.INLOBBY ? false : true} onClick={(e) => { sort("mix") }}
                                style={{ width: '100%', marginTop: '10px', color: lobbyState === ROUND_STATE.INLOBBY ? '' : 'grey' }}>
                                Mix players
                            </button>

                            <button disabled={lobbyState === ROUND_STATE.INLOBBY ? false : true} onClick={(e) => { sort("hcp") }}
                                style={{ width: '100%', marginTop: '10px', color: lobbyState === ROUND_STATE.INLOBBY ? '' : 'grey' }}>
                                Sort by HCP
                            </button>

                            <button disabled={lobbyState === ROUND_STATE.INLOBBY ? false : true} onClick={(e) => { sort("score") }}
                                style={{ width: '100%', marginTop: '10px', color: lobbyState === ROUND_STATE.INLOBBY && game.multiround_num > 1 ? '' : 'grey' }}>
                                Sort by Score
                            </button>

                            <button onClick={(e) => { addMultiRound() }}
                                style={{ width: '100%', marginTop: '10px', color: lobbyState === ROUND_STATE.INLOBBY ? 'grey' : '' }}
                                disabled={lobbyState === ROUND_STATE.INLOBBY ? true : false}
                            >
                                {disableButtonTournament ?
                                    <div className='centering-loading'>
                                        < MoonLoader color="rgba(0, 0, 0, 1)" size={20} />
                                    </div>
                                    :
                                    <> Add tournament round <Tooltip text={TOOLTIP.ADD_TOURNAMENT} /></>
                                }

                            </button>


                            {lobbyState === ROUND_STATE.INLOBBY ?
                                <div style={{ marginTop: '30px' }}>
                                    Start a game from excel file: <Tooltip text={TOOLTIP.FROM_FILE} />
                                </div>
                                :
                                <div style={{ marginTop: '30px' }}>
                                    Upload R2: <Tooltip text={TOOLTIP.FROM_FILE} />
                                </div>
                            }
                            <div style={{ marginTop: '10px', width: '100%', display: 'flex' }} >

                                <input type="file" name="file" style={{ color: 'black' }} onChange={(e) => { changeHandler(e) }} />
                                <button onClick={handleSubmission} style={{ marginLeft: '10px' }} >Upload</button>

                                {lobbyState === ROUND_STATE.INLOBBY ?
                                    <a href={excelTemplate} download="GolfingbombsTemplate" style={{ display: 'block', marginLeft: 'auto' }} >
                                        <button style={{ height: '39px' }} href={excelTemplate} > Download template </button>
                                    </a>
                                    :
                                    <button style={{ height: '39px', marginLeft: 'auto', overflow: 'hidden' }} onClick={() => { downloadFinished() }}> Download finished round </button>
                                }

                            </div>

                            {msg}

                        </>

                        : null}

                </div>

            </div>
            {lobbyState === ROUND_STATE.LIVE ?
                <div className="centering-container" style={{ height: '50px', fontSize: '16pt', fontWeight: '700', backgroundColor: 'rgba(226, 226, 226, 0.5)', marginBottom: '-20px' }}></div>
                : null
            }
            <div style={{ position: 'relative' }}>

                <DndContext
                    sensors={lobbyState === ROUND_STATE.INLOBBY ? sensors : sensorsOff} // If the game is locked, disable touch.
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}>

                    <SortableContext
                        items={player}
                        strategy={rectSwappingStrategy}>

                        <div className='dnd-container' >
                            {player.map((item, index) =>
                                <SortableItem
                                    key={item.id}
                                    player={item}
                                    game={game}
                                    index={index}
                                    setUpdate={() => setUpdate(true)}
                                    lobbyState={lobbyState}
                                />
                            )}
                        </div>
                    </SortableContext>
                </DndContext>

                {lobbyState === ROUND_STATE.LIVE ?

                    <div style={{ position: 'absolute', backgroundColor: 'rgba(226, 226, 226, 0.5)', zIndex: 50, width: '100%', height: '100%', top: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img className="start-button" src="/images/icon_unlock.png" alt="unlock_round_img" onClick={() => unlockLobby()} style={{}}></img>
                    </div>
                    : null
                }

            </div>

            {lobbyState === ROUND_STATE.INLOBBY ?
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <button className='plusminus rad-button' onClick={(e) => { removeGroup() }}> - </button>
                    <button className='plusminus rad-button' onClick={(e) => { addGroup() }} style={{ marginLeft: '20px' }}> + </button>
                </div>
                : null}

            <div style={{ marginTop: '50px', width: '100%', padding: '10px' }}>

                {lobbyState === ROUND_STATE.INLOBBY ?
                    <button className='rad-button' onClick={() => handleStartButton()} disabled={disabledStartButton} style={{ width: '100%' }}> Start </button>
                    : null}

                {lobbyState === ROUND_STATE.LIVE ?
                    <>
                        <button className='rad-button' onClick={() => navigate("/scorecard")} style={{ width: '100%', marginTop: '5px', fontSize: '12pt' }}> Go to scorecard </button>
                        <button className='rad-button' onClick={() => unlockLobby()} style={{ width: '100%', marginTop: '5px', fontSize: '12pt' }}> Unlock </button>
                    </> : null
                }
                <button className='rad-button' onClick={() => handleCloseButton()} style={{ width: '100%', fontSize: '12pt', marginTop: '5px' }}> Quit round </button>
            </div>

            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setQrCode(false)
                }}
                qrCode={qrCode}
                qrCodeLink={game.qr_code_link}
            />
        </>
    )

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            setPlayer((items) => {

                const oldIndex = items.findIndex((item) => {
                    return item.id === active.id
                });

                const newIndex = items.findIndex((item) => {
                    return item.id === over.id
                });

                // Send to backend
                var body = {
                    "from_index": oldIndex,
                    "to_index": newIndex
                };

                const set_data = async () => {
                    await (
                        await fetch('/api/lobby/move_player', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token
                            },
                            body: JSON.stringify(body)
                        })
                    ).json()

                    setUpdate(true)
                };

                set_data();

                return arraySwap(items, oldIndex, newIndex);
            });
        }
    }

    function handleStartButton() {

        setDisabledStartButton(true)

        var body = {
        };
        const set_data = async () => {
            const data = await (
                await fetch('/api/round/start', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            if (data.msg === 'Only the creator of the round can start.') {
                alert(data.msg)
            }
            else if (data.msg === ERRORCODES.SUCCESS) {

                props.setRound(data.round_uuid)
                setLobbyState(ROUND_STATE.LIVE);
                navigate("/scorecard");

            } else {
                alert(data.msg)
            }

        };
        set_data();
    }

    function handleCloseButton(event) {
        var body = {
        };

        if (window.confirm("Do you really want to close the round? You can activate this round again via the rounds tab in the menu.")) {

            const set_data = async () => {
                await (
                    await fetch('/api/lobby/close', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                        body: JSON.stringify(body)
                    })
                ).json()

                props.setUpdate()
                navigate("/newround")
            };

            set_data();

        }
    }

    function addGroup() {

        var body = {
        };

        const set_data = async () => {
            await (
                await fetch('/api/lobby/add_group', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()
            setUpdate(true)
        };
        set_data();
    }

    function removeGroup() {

        var body = {
        };

        const set_data = async () => {
            await (
                await fetch('/api/lobby/del_group', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            setUpdate(true)

        };

        set_data();

    }

    function sort(value) {

        var body = {
            value: value
        };

        const set_data = async () => {
            await (
                await fetch('/api/lobby/sort', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            setUpdate(true)

        };
        set_data();
    }

    function downloadFinished() {

        var body = {
        };

        const get_data = async () => {
            const data = await (
                await fetch('/api/round/download_excel_file', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).blob()

            let url = window.URL.createObjectURL(data);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'golfingbombs.xlsx';
            a.click();

        };

        get_data();

    }

    function unlockLobby() {

        if (!window.confirm("Are you sure you want to unlock the lobby? Game will restart for all players.")) {
            return;
        };

        var body = {
            "round_uuid": round_uuid
        };

        const set_data = async () => {
            const data = await (
                await fetch('/api/round/edit', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            if (data.msg === "Success") {
                setUpdate(true)
            } else {
                alert(data.msg)
            }

        };

        set_data();
    }

    function use_hcp(YesNo) {

        var body = {
            "useHCP": YesNo
        };

        const set_data = async () => {
            await (
                await fetch('/api/round/use_hcp', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            setUpdate(true)

        };

        set_data();

    }

    function show_winner(YesNo) {

        var body = {
            "show_winner": YesNo
        };

        const set_data = async () => {
            await (
                await fetch('/api/round/show_winner', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()
            setUpdate(true)
        };
        set_data();
    }

    function set_roundEvent(round_event) {

        var body = {
            "round_event": round_event
        };

        const set_data = async () => {
            await (
                await fetch('/api/round/round_event', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()
            setUpdate(true)
        };
        set_data();
    }

    function set_skinscarry(YesNo) {

        var body = {
            "skins_carry": YesNo
        };

        const set_data = async () => {
            await (
                await fetch('/api/round/skinscarry', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()
            setUpdate(true)
        };
        set_data();
    }

    function set_players_per_ball(value) {

        var body = {
            "ppb": value
        };

        const set_data = async () => {
            await (
                await fetch('/api/round/ppb', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()
            setUpdate(true)
        };
        set_data();
    }

    function handleScoreCount(score_count) {

        var body = {
            "score_count": score_count
        };

        const set_data = async () => {
            await (
                await fetch('/api/round/bestball', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

        };

        set_data();

    }

    function changeRoundName() {
        var body = {
            "round_name": round_name
        };

        const set_data = async () => {
            await (
                await fetch('/api/lobby/change_round_name', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

        };

        set_data();

    }

    function changeGamemode(e) {

        setGamemode(e);

        var body = {
            "round_uuid": round_uuid,
            "gamemode": e.value
        };

        const set_data = async () => {
            await (
                await fetch('/api/round/change_gamemode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json();
            setUpdate(true)
        };

        set_data();
    }

    function addMultiRound() {
        if (window.confirm("Do you want to add another round? Make sure all scores are reported correctly.")) {

        } else {
            return;
        }

        setDisabledButtonTournament(true)

        var body = {
        };

        const set_data = async () => {
            await (
                await fetch('/api/round/add_round', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                })
            ).json()

            setUpdate(true)
            setDisabledButtonTournament(false)

        };
        set_data();
    }
}


