import React from "react"
import { useState, useEffect } from 'react';
import './EventPage.css'
import MoonLoader from "react-spinners/MoonLoader";
import { useNavigate } from "react-router-dom"

export const EventPage = (props) => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState()

  useEffect(() => {
    const get_data = async () => {
      const data = await (
        await fetch('/api/events', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          }
        })

      ).json();

      setEvents(data);
      props.setUpdate()
      setLoading(false);

    };

    get_data();

  }, []);


  const Event = (props) => {

    return (
      <>
        <div className="event-container"
          onClick={() => { navigate(props.data.link, { replace: true }) }}
        >
          {props.data.text} <br /> {props.data.time}
        </div>
      </>
    )
  }

  if (isLoading) {
    return <>
      <div className='centering-loading'>
        < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
      </div>
    </>;
  }


  return (
    <>

      <div className="centering-container">
        <div className="round-container">
          {events.length === 0 ?
            <div style={{ padding: '30px', color: 'white' }}>
              No events to show
            </div>
            :
            <>
              {events.map((item, index) =>
                <Event
                  key={index}
                  data={item}
                />
              )}

            </>
          }
        </div>
      </div>
    </>
  )

}