import React from 'react'
import { useState, useEffect } from 'react';
import './Leaderboard.css'
import ScorecardExpanded from './ScorecardExpanded'
import { isBestBall, isSkins, isStableford, isStrokePlay } from '../misc/checks';
import MoonLoader from "react-spinners/MoonLoader";

const LeaderboardCard = (props) => {

    const [isExpanded, setIsExpanded] = useState('')
    const [expandedData, setExpandedData] = useState()
    const [isLoading, setLoading] = useState(true);

    const data = props.data

    function handleExpandScorecard() {

        if (isExpanded) {
            setIsExpanded(false)
        } else {
            const get_data = async () => {

                const data = await (
                    await fetch('/api/scorecard_expanded?' + new URLSearchParams({
                        uuid: props.data.uuid,
                        round_uuid: props.data.round_uuid
                    }), {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + props.token
                                }   
                            })
                ).json();

                setExpandedData(data)
                setLoading(false)
            
            };
            get_data();
            setIsExpanded(true)
        }
    }

    return (
        <>
            <div className='leaderboard-container' onClick={handleExpandScorecard} >

                {/* {isBestBall(props.game_format) ? (
                    <div className='leaderboard-player'>
                        <div className='leaderboard-position'> {props.index + 1}. </div>
                        <div className='leaderboard-nickname'>
                            <span className='lb-p1'>  {data.nickname}   </span>
                        </div>
                        <div className='leaderboard-today' style={{ color: data.today < 0 ? 'red' : '' }}> {props.multiround_num > 1 ? data.today : ''}  </div>
                        <div className='leaderboard-to_par' style={{ color: data.to_par_points < 0 ? 'red' : '' }}> {data.to_par_points}</div>
                        <div className='leaderboard-thru'> {data.thru}</div>
          
                    </div>
                )
                :
                ( */}
                <div className='leaderboard-player'>
                    <div className='leaderboard-position'> {props.index + 1}. </div>
                    {isBestBall(props.game_format) ?
                        <div className='leaderboard-nickname'>
                            <span className='lb-p3'>  {data.nickname}   </span>
                        </div>
                        :
                        <div className='leaderboard-nickname'>
                            <span className='lb-p1'>  {data.nickname}   </span>
                            <span className='lb-p2'>  {data.tee} {data.shcp === "" ? null : '|'}  {data.shcp} </span>
                        </div>
                    }
              
                    <div className='leaderboard-today' style={{ color: data.today < 0 ? 'red' : '' }}> {props.multiround_num > 1 ? data.today : ''}  </div>

                    {isStableford(props.game_format) || props.secondary ?
                        <div className='leaderboard-to_par' style={{ color: data.to_par_points < 0 ? 'red' : '' }}> {data.to_par_points}</div>
                        :
                        null
                    }

                    {isStrokePlay(props.game_format) && !props.secondary ?
                        <div className='leaderboard-to_par' style={{ color: data.to_par_strokes_hcp < 0 ? 'red' : '' }}> {data.to_par_strokes_hcp}</div>
                        :
                        null
                    }

                    {isSkins(props.game_format) && !props.secondary ?
                        <div className='leaderboard-to_par' > {data.skins} </div>
                        :
                        null
                    }

                    {isBestBall(props.game_format) ?
                        <div className='leaderboard-to_par' style={{ color: data.to_par_points < 0 ? 'red' : '' }}> {data.to_par_points}</div>
                        :
                        null
                    }


                    <div className='leaderboard-thru'> {data.thru}</div>
                </div>

                <div className='leaderboard-player-expanded' style={{ display: isExpanded ? '' : 'none' }}>
                    {isExpanded ? (
                        (() => {
                            if (isLoading) {
                                return <>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
                                    </div>
                                </>;
                            } else {
                                return (
                                    <ScorecardExpanded data={expandedData} />
                                )
                            }
                        })()

                    ) : (
                        null
                    )}

                </div>

            </div>
        </>
    )
}

export default LeaderboardCard

