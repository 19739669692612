import React from 'react';
import './CoursePage.css'
import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import MoonLoader from "react-spinners/MoonLoader";
import Select from 'react-select';
import { miniStyle } from "../misc/styles";

export const CoursePage = (props) => {
  const navigate = useNavigate();

  const [courseName, setCourseName] = useState()
  const [courseParTotal, setCourseParTotal] = useState()

  const [isLoading, setLoading] = useState(true);

  const courseDataArray = [];
  courseDataArray.push({ par: '', idx: '' });

  const [courseData, setCourseData] = useState(courseDataArray)

  const teeArray = [];
  teeArray.push({ uuid: '', name: '', slope_rating: '', course_rating: '', length: '', gender: '' });
  const [tee, setTee] = useState(teeArray)

  //If we're editing a course, this will show up.
  const [searchParams, setSearchParams] = useSearchParams();
  var course_uuid = searchParams.get("course_uuid")
  const [courseUuid, setCourseUuid] = useState(course_uuid)

  const [disableButton, setDisableButton] = useState(false)


  useEffect(() => {

    const get_data = async () => {
      const data = await (
        await fetch('/api/course/edit?' + new URLSearchParams({
          course_uuid: course_uuid
        }), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          }
        })
      ).json();

      setCourseData(data.course_data)
      setTee(data.tee_data)

      setCourseName(data.name)
      setCourseParTotal(data.par_total)

      setLoading(false);

    };

    if (course_uuid !== '') {
      get_data();
    } else {

      for (let i = 0; i < 17; i++) {
        courseDataArray.push({ par: '', idx: '' });
      }
      setCourseData(courseDataArray)
      setLoading(false);
    }

  }, []);

  const CourseTee = (props) => {

    const [name, setName] = useState(props.name)
    const [courseRating, setCR] = useState(props.CR)
    const [slopeRating, setSR] = useState(props.SR)
    const [length, setLength] = useState(props.length)
    const [gender, setGender] = useState()
    
    useEffect(() => {
      if (props.gender === true) {
        setGender({ value: 1, label: "Male" })
      } else if (props.gender === false) {
        setGender({ value: 0, label: "Female" })
      } else {
        setGender({ value: 3, label: "Unknown" })
      }
      
      
    }, [])

    return (
      <div className="tee-item">
        <input className='tee-input' type='text' placeholder="Tee (M)" autoComplete="new-password"
          value={name || ''}
          onChange={(e) => {
            setName(e.target.value);
            props.setName(e.target.value)
          }}
        ></input>

        <input id="sr" className='tee-input' type='float' placeholder="123" autoComplete="new-password"
          value={slopeRating || ''}
          onChange={(e) => {
            setSR(e.target.value);
            props.setSR(e.target.value)
          }}
        ></input>

        <input id="cr" className='tee-input' type='text' placeholder="72" autoComplete="new-password"
          value={courseRating || ''}
          onChange={(e) => {
            setCR(e.target.value);
            props.setCR(e.target.value)
          }}
        ></input>

        <Select
          styles={miniStyle}
          className="input-setup teeholder"
          options={[{ value: 1, label: "Male" }, { value: 0, label: "Female" }]}
          value={(gender)}
          onChange={(e) => { 
            setGender({ value: e.value, label: e.label });
            props.setGender(e.value)
          }}
          isSearchable={false}
        />

        <input className='tee-input' type='text' placeholder="5000" autoComplete="new-password"
          value={length || ''}
          onChange={(e) => {
            let tmp = parseInt(e.target.value);
            setLength(tmp);
            props.setLength(tmp)
          }}
        ></input>

        <div className="del-tee" onClick={() => {
          props.delTee()
        }} >X</div>
      </div>
    )
  }

  const CourseHole = (props) => {

    const [par, setPar] = useState(props.par)
    const [idx, setIndex] = useState(props.idx)

    return (
      <div className="course-item">
        <span className='course-hole'> {props.hole} </span>

        <input className='course-input' type='text' placeholder="4" id={props.parId} autoComplete="off"
          value={par || ''}
          onChange={(e) => {
            setPar(parseInt(e.target.value))
            props.setPar(parseInt(e.target.value));
            props.changeFocus('hole-' + (props.hole))
          }}
        ></input>

        <input className='course-input' type='text' placeholder="9" id={props.idxId} autoComplete="off"
          value={idx || ''}
          onChange={(e) => {
            setIndex(parseInt(e.target.value))
            props.setIdx(parseInt(e.target.value));
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              props.changeFocus('idx-' + (props.hole))
            }
          }}
        ></input>
      </div>)
  }

  if (isLoading) {
    return <>
        <div className='centering-loading'>
            < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
        </div>
    </>;
  }

  return (
    <>
      <div className="course-container">
        <div className="course-name-container">
          <input style={{ border: 'none', textAlign: 'center', height: '100%' }}
            value={courseName || ''}
            onChange={(e) => setCourseName(e.target.value)}
            type='text'
            placeholder="Course Name"
          ></input>
        </div>

        <div className="course-item">
          <span className='course-hole'> Hole </span>
          <span className='course-par'> Par </span>
          <span className='course-idx'> Index </span>
        </div>

        {courseData.map((item, index) =>
          <CourseHole key={index}
            hole={index + 1}
            parId={'hole-' + index}
            idxId={'idx-' + index}

            par={item.par}
            idx={item.idx}

            setPar={(e) => { courseData[index].par = e; }}
            setIdx={(e) => { courseData[index].idx = e}}

            changeFocus={(e) => {
              var element = document.getElementById(e)
              if (element != null) {
                element.focus()
              }
            }}

          />
        )}

        <div className="course-item-par">
          <input style={{ border: 'none', textAlign: 'center', height: '100%' }}
            placeholder="72"
            value={courseParTotal || ''}
            onChange={(e) => setCourseParTotal(e.target.value)}
          ></input>
        </div>

        <div className="tee-container">
          <div className="tee-header">
            <span className='tee-h1'>
              Name
            </span>
            <span className='tee-h1'>
              SR
            </span>
            <span className='tee-h1'>
              CR
            </span>
            <span className='tee-h1'>
              Gender
            </span>
            <span className='tee-h1'>
              Length
            </span>
          </div>

          {tee.map((item, index) =>
            <CourseTee key={index}
              index={index}
              data={item}
              name={item.name}
              CR={item.course_rating}
              SR={item.slope_rating}
              gender={item.gender}
              length={item.length}

              setName={(e) => { tee[index].name = e }}
              setCR={(e) => { tee[index].course_rating = e }}
              setSR={(e) => { tee[index].slope_rating = e }}
              setLength={(e) => { tee[index].length = e }}
              setGender={(e) => { tee[index].gender = e } }

              delTee={(e) => {
                let tmp = [...tee];
                tmp.splice(index, 1)
                setTee(tmp)
              }}

            />
          )}

          <button style={{ margin: 'auto', padding: '5px', marginTop: '15px' }} onClick={() => handleAddTee()}> Add new blank tee</button>

        </div>


        {course_uuid !== '' ?
          <button onClick={() => handleDelCourse()} style={{ marginTop: '50px' }} > Delete Course </button>
          : ''}

        <button onClick={() => handleAddUpdateCourse()} style={{ marginLeft: 'auto', marginTop: '50px' }} disabled={disableButton}>
            {course_uuid !== '' ? "Update Course" : "Create new course"}
          </button>

      </div>
    </>
  );

  function handleAddTee() {
    const array = ({ uuid: '', name: '', slope_rating: '', course_rating: '', length: '', gender:''});
    setTee(oldArray => [...oldArray, array]);
  }

  function handleAddUpdateCourse() {
    setDisableButton(true)

    var body = {
      "course_name": courseName,
      "course_data": courseData,
      "tee_arr": tee,
      "par": courseParTotal,
      "course_uuid": courseUuid,
    };

    const set_data = async () => {

      const data = await (
        await fetch('/api/course', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          },
          body: JSON.stringify(body)
        })
      ).json()

      if (data.msg == 'Success') {
        navigate('/newround')
      } else {
        alert(data.msg)
        setDisableButton(false)
      }

    };

    set_data();

  }

  function handleDelCourse() {

    if (window.confirm("Do you really want to delete the course? ")) {
    }

    var body = {
      "course_uuid": courseUuid
    };

    const set_data = async () => {

      const data = await (
        await fetch('/api/course/delete', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          },
          body: JSON.stringify(body)
        })
      ).json()      
      
      if (data.msg == 'Success') {

        sessionStorage.removeItem("gc_uuid");
        sessionStorage.removeItem("gc_name");


        navigate('/newround')
        

      } else {
        alert(data.msg)
      }

    };

    set_data();

  }
}

