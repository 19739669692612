import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import ScorecardExpanded from '../components/ScorecardExpanded';
import MoonLoader from "react-spinners/MoonLoader";

export const FinishedRoundPage = (props) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState()

  useEffect(() => {

    const get_data = async (url) => {

      const data = await (
        await fetch('/api/scorecard/finished', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          }
        })
      ).json();

      setData(data)
      setLoading(false);

    };

    get_data();

  }, []);

  const FinishedPlayer = (props) => {

    return (
      <>

        <div style={{ backgroundColor: 'white', margin: '10px' }}>
          <ScorecardExpanded data={props.data} />

          <div style={{ fontSize: '20pt', display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'baseline' }}>
            {props.data.total_strokes} <sub>  {props.data.total_sub} </sub> &nbsp; | &nbsp;
            <span style={{
              color: props.data.to_par < 0 ? 'red' : ''
            }}>
              {props.data.to_par} </span>
          </div>

          <button style={{backgroundColor: props.data.signed ? "green" : "", marginLeft: '10%', width: "80%", marginBottom: "20px", marginTop: "20px"}} onClick={(e) => {signUser(e, props.data.uuid, props.data.name)}}>  Sign  {props.data.name}'s' score  </button>

        </div>
      </>
    )

  }

  if (isLoading) {
    return <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
      </div>
    </>;
  }

  return (
    <>

      {data.map((item, index) =>
        <FinishedPlayer key={index} data={item} />
      )}

      <div className="centering" style={{ margin: '30px', display: 'flex', flexWrap: 'wrap' }}>
        <button className='rad-button' onClick={() => navigate('/leaderboard')} style={{ width: '100%', fontSize: '12pt', marginTop: '5px' }}> Leaderboard </button>
        <button className='rad-button' onClick={() => handleClose()} style={{ width: '100%', fontSize: '12pt', marginTop: '5px' }}> Quit round </button>
      </div>
    </>
  )

  function handleClose() {
    var body = {
    };

    const set_data = async () => {
      const data = await (
        await fetch('/api/lobby/close', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          },
          body: JSON.stringify(body)
        })
      ).json()

      props.setUpdate()
      navigate("/newround")

    };

    set_data();
  }

  function signUser(e, uuid, name) {

    if (window.confirm("Are you sure you would like to sign " + name + " ? scorecard - this cannot be undone")) {
    }

    e.target.style.backgroundColor = "green";

    var body = {
      uuid: uuid
    };

    const set_data = async () => {
      const data = await (
        await fetch('/api/scorecard/sign', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          },
          body: JSON.stringify(body)
        })
      ).json()

    };

    set_data();
  }

}

