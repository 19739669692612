import React from "react"
import { useState } from "react";
import './AboutPage.css'
import { Helmet } from "react-helmet";


export const AboutPage = () => {

  const [name, setName] = useState();
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState();
  const [text, setText] = useState();
  const [msg, setMsg] = useState();


  function handleSubmit() {

    var body = {
      'name': name,
      'email': email,
      'subject': subject,
      'text': text
    };

    const set_data = async () => {

      const data = await (
        await fetch('/api/contact', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        })
      ).json()

      setMsg(data.msg)

    };
    set_data();
  }

  return (
    <>

      <Helmet>
        <title>About - Golfingbombs</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Get in touch with us. Share your feedback and queries." />
        <link rel="canonical" href="https://www.golfingbombs.com/about" />
      </Helmet>

      <div className="centering-container">
        <div className="gb-background"  >

          <div style={{ height: '50px', width: '100%' }}>
            Got any feedback? Write to us!
          </div>

          <form className='feedback-form' onSubmit={handleSubmit}>

            <div className="form-ctrl">
              <label>Name</label>
              <input type='text' placeholder=""
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
              >
              </input>
            </div>

            <div className="form-ctrl">
              <label>Email</label>
              <input type='text' placeholder=""
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
              >
              </input>
            </div>

            <div className="form-ctrl">
              <label>Subject</label>
              <input type='text' placeholder=""
                value={subject || ''}
                onChange={(e) => setSubject(e.target.value)}
              >
              </input>
            </div>

            <div className="form-ctrl">
              <textarea name="subject" placeholder="Write here"
                value={text || ''}
                onChange={(e) => setText(e.target.value)}
              >
              </textarea>
            </div>

            <input type='submit'></input>

          </form>

          {msg}

        </div>
      </div>
    </>

  )
}