import React from 'react'
import { useState, useEffect } from 'react';
import { useTable } from 'react-table'
import { GAMEMODE, ROUNDSTATE } from "../misc/enums"
import MoonLoader from "react-spinners/MoonLoader";

const LeaderboardMatchExpanded = (props) => {
  const [data, setData] = useState()
  const [p1_data, setP1Data] = useState()
  const [p2_data, setP2Data] = useState()
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {

    const get_data = async () => {

      const data = await (
        await fetch('/api/leaderboard_match_expanded?' + new URLSearchParams({
          uuid: props.p1_uuid,
          round_uuid: props.round_uuid
        }), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          }
        })
      ).json();

      setData(data)
      setP1Data((data.p1_match_points))
      setP2Data((data.p2_match_points))
      setLoading(false);

    };
    get_data();
  }, []);

  const numbers = [...Array(19).keys()]

  if (isLoading) {
    return <>
        <div className='centering-loading'>
            < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
        </div>
    </>;
  }

  function formatRedScore(strokes, points, win) {

    let text = <span
      style={{
        backgroundColor: win === 1 ? 'rgba(255, 0, 0, 0.5)' : '',
        borderRadius: "5px",
        padding: points === '' ? '2px 9px 2px 6px' : '2px 5px 2px 5px',
      }}> {strokes} </span> 

    return text
  }


  function formatBlueScore(strokes, points, win) {

    let text = <span
      style={{
        backgroundColor: win === 1 ? 'rgba(0, 0, 255, 0.5)' : '',
        borderRadius: "5px",
        padding: points === '' ? '2px 9px 2px 6px' : '2px 5px 2px 5px',
      }}> {strokes}  </span> 
      
    return text

  }

  return (

    <>
      <div style={{ height: '10px' }}></div>

      <table >
        <tbody className='matchplay'>
          <tr>
            <td className='leaderboard-match-expanded-cell' style={{display: 'flex', width:'30px'}}> Hole </td>
            {numbers.slice(1, 10).map((item, index) =>
              <td className='leaderboard-match-expanded-cell' key={index}> {item} </td>
            )}

          </tr>

          <tr>
            <td className='leaderboard-match-expanded-cell' style={{display: 'flex', width:'30px'}}> {data.p1_name}  </td>
            {numbers.slice(1, 10).map((item, index) =>
              <td className='leaderboard-match-expanded-cell' key={index}> {formatRedScore(data.p1_strokes[index], p1_data[index], data.p1_wins[index])} </td>
            )}
          </tr>

          <tr style={{borderTop: '2px solid #d8d8d8'}}>
            <td className='leaderboard-match-expanded-cell' style={{display: 'flex', width:'30px'}}> {data.p2_name} </td>
            {numbers.slice(1, 10).map((item, index) =>
              <td className='leaderboard-match-expanded-cell' key={index}> {formatBlueScore(data.p2_strokes[index], p2_data[index], data.p2_wins[index])} </td>
            )}
          </tr>

          <tr >
            <td className='leaderboard-match-expanded-cell'> Hole </td>
            {numbers.slice(10, 19).map((item, index) =>
              <td className='leaderboard-match-expanded-cell' key={index}> {item} </td>
            )}
          </tr>

          <tr>
            <td className='leaderboard-match-expanded-cell'>  </td>
            {numbers.slice(10, 19).map((item, index) =>
              <td className='leaderboard-match-expanded-cell' key={index}> {formatRedScore(data.p1_strokes[index + 9], p1_data[index + 9], data.p1_wins[index + 9])} </td>
            )}
          </tr>

          <tr style={{borderTop: '2px solid #d8d8d8'}}>
            <td className='leaderboard-match-expanded-cell'>  </td>
            {numbers.slice(10, 19).map((item, index) =>
              <td className='leaderboard-match-expanded-cell' key={index}> {formatBlueScore(data.p2_strokes[index + 9], p2_data[index + 9], data.p2_wins[index + 9])} </td>
            )}
          </tr>


        </tbody>
      </table>
    </>


  )
}

export default LeaderboardMatchExpanded
