import React from 'react'
import './LeaderboardCardMatch.css'
import { useState } from 'react'
import LeaderboardMatchExpanded from './LeaderboardMatchExpanded'
import { GAMEMODE } from "../misc/enums"


const LeaderboardCardMatch = (props) => {
  let aTemp = false;
  let bTemp = false;

  let dt = JSON.parse(props.data.score_relative)
  if (dt[0] == dt[1]) {
    aTemp = false
    bTemp = false
  }
  else if (dt[0] > dt[1]) {
    aTemp = true
    bTemp = false
  }
  else if (dt[1] > dt[0]) {
    aTemp = false
    bTemp = true
  }

  const [isExpanded, setIsExpanded] = useState('')
  const [leftAdvantage, setLeftAdvantage] = useState(aTemp)
  const [rightAdvantage, setRightAdvantage] = useState(bTemp)

  function handleExpandScorecard() {

    if (isExpanded) {
      setIsExpanded(false)
    } else {
      setIsExpanded(true)
    }
  }

  const redStyle = {
    backgroundColor: '#df2032',
  }

  const blueStyle = {
    backgroundColor: '#3155ce',
  }

  const greyStyle = {
    backgroundColor: '#e0e0e0',
  }

  function styleTeamScore(color) {

    if (color == 'red') {
      return redStyle
    } else if (color == 'blue') {
      return blueStyle
    } else {
      return greyStyle
    }
  }

  function displayScore() {
    let dt = JSON.parse(props.data.score_relative)
    let fn = props.data.finished
    let score = ''
    let color = ''

    if (dt[0] == dt[1]) {
      if (fn != '') {
        score = fn
      }
      else {
        score = "A/S"
      }
      color = 'grey'
    }
    else if (dt[0] > dt[1]) {

      if (fn != '') {
        score = fn
      }
      else {
        score = dt[0].toString() + "UP"
      }

      if (props.game_format == GAMEMODE.MATCHPLAYTEAMS) {
        color = 'red'
      } else {
        color = 'grey'
      }
    }
    else if (dt[1] > dt[0]) {

      if (fn != '') {
        score = fn
      }
      else {
        score = dt[1].toString() + "UP"
      }

      if (props.game_format == GAMEMODE.MATCHPLAYTEAMS) {
        color = 'blue'
      } else {
        color = 'grey'
      }
    }

    let text =
      <div style={styleTeamScore(color)} className="middle-score">
        <span style={{ width: '100%', textAlign: 'center', padding: '0px', marginTop: '10px', fontWeight:'700' }}> {score} </span>
        <span style={{ fontSize: '12pt', padding: '0px', marginBottom: '10px' }}> {props.data.thru !== 0 ? 'Thru ' + props.data.thru : ' '}  </span>
      </div>
    return text
  }

  return (

    <div className='leaderboard-match-container' onClick={() => handleExpandScorecard()}>

      <div className="leaderboard-match-player">
        <div className="left-player">
          <span className='l1' style={{ fontSize: '16pt', fontWeight:'700' }}>  {props.data.p1}  </span>
          <span className='l2'> {props.data.p1_shcp} </span>
          {leftAdvantage ?
            <div className="advantage-shape-left">
              <img className="advantage-left" src="/images/matchplay_advantage.png"></img>
            </div>
            : null}

        </div>

        {displayScore()}

        <div className="right-player" >
          <span className='l1' style={{ justifyContent: 'flex-end', fontSize: '16pt', fontWeight:'700' }}>  {props.data.p2}  </span>
          <span className='l2'>  {props.data.p2_shcp} </span>
          {rightAdvantage ?
            <div className="advantage-shape-right">
              <img className="advantage-right" src="/images/matchplay_advantage.png"></img>
            </div>
            : null}
        </div>
      </div>

      <div className='leaderboard-match-expanded' style={{ display: isExpanded ? '' : 'none' }}>

        {isExpanded ? (
          <LeaderboardMatchExpanded 
          p1_uuid={props.data.p1_uuid} 
          round_uuid={props.round_uuid} 
          game_format={props.game_format} 
          />
        ) : (
          ""
        )}

      </div>

    </div>
  )




}

export default LeaderboardCardMatch
