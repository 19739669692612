import React from "react";

import { Helmet } from "react-helmet";

export const TermsOfServicePage = (props) => {


    return (
        <>
          <Helmet>
                <title>Golfingbombs - Terms of Service</title>
                <meta charSet="utf-8" name="description" content="The Terms of Service of Golfingbombs, governing usage of the website." />
                <link rel="canonical" href="https://www.golfingbombs.com/tos" />
            </Helmet>

            <div className="centering-container">
                <div className="gb-background" style={{ width: '95%' }}>
                    <p>
                        <h1> Terms of Service </h1>
                        These Terms of Service govern your use of www.golfingbombs.com ("the Website"), owned and operated by Golfingbombs. By accessing or using the Website, you agree to comply with these terms.
                    </p>
                    <p>
                        <h5>1. Acceptance of Terms</h5>
                        By accessing the Website, you agree to be bound by these TOS, all applicable laws, and regulations. If you do not agree with any part of these terms, you may not access the Website.
                    </p>

                    <p>
                        <h5>2. Use License</h5>
                        a. Permission is granted to temporarily access the materials on the Website for personal, non-commercial use only. This is the grant of a license, not a transfer of title.
                        b. You may not:
                        i. Modify or copy the materials;
                        ii. Use the materials for any commercial purpose or public display;
                        iii. Attempt to decompile or reverse engineer any software contained on the Website;
                        iv. Remove any copyright or other proprietary notations from the materials.
                    </p>
                    <p>
                        <h5>3. Disclaimer</h5>
                        The materials on the Website are provided on an 'as is' basis. Golfingbombs makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                    </p>
                    <p>
                        <h5>4. Limitations</h5>
                        In no event shall Golfingbombs or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the Website.
                    </p>
                    <p>
                        <h5>5. Modifications</h5>
                        Golfingbombs may revise these terms of service for the Website at any time without notice. By using this Website, you agree to be bound by the then-current version of these TOS.
                    </p>
                    <p>
                        <h5>6. Governing Law</h5>
                        These terms and conditions are governed by and construed in accordance with the laws of Sweden, and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                    </p>
                    <p>
                        <h5>7. Privacy</h5>
                        Use of the Website is also governed by our Privacy Policy, which can be found <a href="/privacypolicy"> here</a>
                    </p>
                    <p>
                        <h5>8. Contact Information</h5>
                    </p>
                    If you have any questions about these TOS, please contact <a href="/about">us</a>.
                </div>
            </div>
        </>
    )

}