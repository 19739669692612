import { useState, useEffect, useRef } from 'react';
import './PreviousRound.css'
import { useNavigate } from "react-router-dom"
import { ROUND_STATE } from '../misc/enums'
import MoonLoader from "react-spinners/MoonLoader";
import { isMatchPlay } from '../misc/checks'


const PreviousRoundCard = (props) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState('')

  function handleExpand() {

    if (isExpanded) {
      setIsExpanded(false)
    } else {
      setIsExpanded(true)
    }
  }

  const PrevRoundExpanded = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState()

    function formatScore(data) {
      data = JSON.parse(data)
      var rt = ""
      if (data[0] === data[1]) {
        rt = <span className='prev-mp-center' style={{backgroundColor:'#e0e0e0'}}> A/S </span>
      } else if (data[0] > data[1]) {
        rt = <span className='prev-mp-center' style={{backgroundColor:'#df2032'}}> {data[0].toString() + "UP"} </span>
      } else if (data[0] < data[1]) {
        rt = <span className='prev-mp-center' style={{backgroundColor:'#3155ce'}}> {data[1].toString() + "UP"}</span>
      }

      return rt
    }

    useEffect(() => {

      const get_data = async () => {
        const data = await (
          await fetch('/api/previously_expanded?' + new URLSearchParams({
            uuid: props.uuid
          }), {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + props.token
            }
          })
        ).json();
        setData(data)
        setLoading(false)
      };

      get_data();

    }, []);

    if (isLoading) {
      return <>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
        </div>
      </>;
    }

    return (
      <>
        {isMatchPlay(props.game_format) ?
          <>
            {data.match_data.map((item, index) =>
              <div key={index} className='prev-lb-container' style={{}}>
                <span className='prev-mp-name' style={{ backgroundColor: JSON.parse(item.score_relative)[0] > 0 ? "#df2032" : "" }}> {item.p1} </span>
                {formatScore(item.score_relative)}
                <span className='prev-mp-name' style={{ justifyContent: 'flex-end', backgroundColor: JSON.parse(item.score_relative)[1] > 0 ? "#3155ce" : "" }}> {item.p2} </span>
              </div>
            )}
          </>
          :
          <>
            {data.leaderboard.map((item, index) =>
              <div key={item.uuid} className='prev-lb-container'>
                <span className='prev-lb-name'> {item.nickname} </span>
                <span className='prev-lb-points' style={{ color: item.to_par_points < 0 ? 'red' : '' }}> {item.to_par_points} </span>
                <span className='prev-lb-strokes'> {item.strokes} </span>
              </div>
            )}
          </>
        }

      </>
    )
  }

  return (
    <>
      <div className='prevround-container' onClick={() => handleExpand()}>

        {isMatchPlay(props.game_format) ?
          <>
            <div className='prevround-player'>
              <span className='prevround-gametype'> {props.gamemode} </span>
              <span className='prevround-name'> {props.round_name} </span>
              <span className='prevround-result' > {props.score_mp} </span>
              <span className='prevround-strokes'>  </span>
              <span id={props.uuid} className='delete-x' onClick={(e) => deleteRound(e)}> x </span>
            </div>
          </>
          :
          <div className='prevround-player'>
            <span className='prevround-gametype'> {props.gamemode} </span>
            <span className='prevround-name'> {props.round_name} </span>
            <span className='prevround-result' style={{ color: props.to_par < 0 ? 'red' : '' }}> {props.to_par} </span>
            <span className='prevround-strokes'> {props.strokes} </span>
            <span id={props.uuid} className='delete-x' onClick={(e) => deleteRound(e)}> x </span>
          </div>
        }
        <div className='prevround-expanded' style={{ display: isExpanded ? '' : 'none' }}>
          {isExpanded ? (
            <>
              <PrevRoundExpanded uuid={props.uuid} token={props.token} game_format={props.game_format} />
              <button id={props.uuid}
                style={{ float: 'right', margin: '10px 0px 10px 0px', backgroundColor: '#e8f293', width: '150px', color: 'black' }}
                onClick={(e) => changeActiveRound(e)}
              > JOIN </button>
            </>
          ) : (
            null
          )}

        </div>
      </div>


    </>
  )

  function deleteRound(e) {
    e.stopPropagation()
    var uuid = e.target.id

    var body = {
      "round_uuid": uuid
    };

    if (window.confirm("Are you sure you want to remove the round? This cannot be undone.")) {

      const set_data = async () => {
        const data = await (
          await fetch('/api/settings/round_delete', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + props.token
            },
            body: JSON.stringify(body)
          })
        ).json()

        props.setUpdate()

      };

      set_data();

    }
  }

  function changeActiveRound(e) {

    e.stopPropagation()
    var uuid = e.target.id;
    var body = {
      "round_uuid": uuid
    };

    const set_data = async () => {

      const data = await (
        await fetch('/api/settings/round_activate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          },
          body: JSON.stringify(body)
        })
      ).json()

      props.updateLobby()

      // if (data.state === ROUND_STATE.LIVE || data.state === ROUND_STATE.FINISHED) {
      // var nav = '/leaderboard?round_uuid=' 
      // navigate('/leaderboard')
      // } else {
      navigate('/editround')
      // }

    };

    set_data();

  }
}

export default PreviousRoundCard
