import React from "react"
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import MoonLoader from "react-spinners/MoonLoader";
import { delay } from "../misc/utils";

export const JoinPage = (props) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [players, setPlayers] = useState()
  const [isLoading, setLoading] = useState(true);

  var round_uuid = searchParams.get("uuid")
  var round_pw = searchParams.get("password")

  useEffect(() => {
    const dataFetch = async () => {

      const data = await (
        await fetch('/api/lobby/join?' + new URLSearchParams({
          round_uuid: round_uuid,
          round_pw: round_pw
        }), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.token
          }
        })

      ).json();

      if (data.msg === 'Success') {
        props.setUpdate()
        navigate('/editround')
      }

      else if(data.msg === 'Round already started') {
        alert("Round already started")
        await delay(1000)
        navigate('/')
      
      } else if (data.msg === 'OpenPlay'){
        props.setUpdate()
        navigate('/lobby/openplay?' + new URLSearchParams({
          round_uuid: round_uuid,
          round_pw: round_pw
        })) 

      } else if (data.msg === 'Failed') {
        setPlayers(data.info)
      }

      setLoading(false);

    };

    dataFetch();

  }, []);

  const PlayerCard = (props) => {

    return (
      <div style={{ zIndex: '0', height: 'auto', backgroundColor: 'white', marginBottom: '15px', padding: '5px', borderRadius: '10px', textAlign: 'center', fontSize: '18pt', position:'relative' }}
        onClick={(event) => handleOnClick(event.target)}
        id={props.id}
      >
        <span style={{position: 'absolute', top:'5px', left:'5px'}}>{props.tee_time}</span>
        <span className='player-name' id={props.id}>{props.name}</span>
        <div className='tee-hcp-container' id={props.id}> {props.tee} | {props.hcp < 0 ? '+' + -1 * props.hcp : props.hcp} | {props.shcp} </div>

      </div>
    )

  }

  if (isLoading) {
    return <>
      <div className='centering-loading'>
        < MoonLoader color="rgba(0, 0, 0, 1)" size={40} />
      </div>
    </>;
  }


  return (

    <>    
      <div style={{ width: '100%', height: 'auto', padding: '15px' }}>
        {players.map((item, index) =>
          <PlayerCard
            key={index}          
            id={item.uuid}
            name={item.name}
            hcp={item.hcp}
            shcp={item.shcp}
            tee={item.tee}
            tee_time={item.tee_time}
          />
        )}
      </div>
    </>
  )

  function handleOnClick(input) {

    var body = {
      "uuid": input.id
    };

    const set_data = async () => {

      const data = await (
        await fetch('/api/lobby/join_qr_list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
      ).json()

      //If an access token is returned, then lets login and move to setup
      if (data.access_token !== undefined) {
        props.setToken(data.access_token)
        props.setUpdate(true)
        navigate("/editround");
      }

    };

    set_data();

  }
}